import { FC, ReactNode, useContext } from "react";
import { createPortal } from "react-dom";
import { FaXmark } from "react-icons/fa6";
import { deviceInfoContext } from "../../../context";
import classes from "./style.module.scss";
const MobileScreen: FC<{
  open: boolean;
  onClose: () => void;
  theme?: "light" | "dark";
  title?: string;
  children: ReactNode;
}> = ({ open, onClose, theme = "light", title, children }) => {
  const { width } = useContext(deviceInfoContext);
  return (
    <>
      {open === true &&
        createPortal(
          <>
            <div
              className={`fixed left-0 bg-white ${classes.pageContainer}`}
              style={{ width: `${width}`, height: "100vh", zIndex: 11 }}
            >
              {title !== "" && (
                <div
                  className="px-2 flex items-center"
                  style={{
                    background: "black",
                    color: "white",
                    height: "3.8rem",
                  }}
                >
                  <span className="font-semibold">{title}</span>
                  <FaXmark
                    className="ml-auto"
                    onClick={onClose}
                    style={{ fontSize: "1.4rem" }}
                  />
                </div>
              )}
              <div>{children}</div>
            </div>
          </>,
          document.body
        )}
    </>
  );
};
export default MobileScreen;
