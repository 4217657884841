/**
 * [get Fetches the value from localstorage]
 * @param  {[String]} _key [Name of the key]
 * @param  {[Boolean]} toParse [To parse the the string or not]
 * @return {[String|Object]} [description]
 */
const handelGetValue = (_key, toParse = false) => {
  let value = "";
  try {
    if (window.localStorage) {
      const data = window.localStorage.getItem(_key);
      if (data && toParse) {
        value = JSON.parse(data);
      } else {
        value = data;
      }
    }
  } catch (error) {
    value = "";
  }
  return value;
};

/**
 * [set Sets the value to the localstorage. All non object values are converted to String]
 * @param {[String]} key [Name of the key]
 * @param {[String|Number|Object|Bool]} value [Value you want to store]
 * @param {[Boolean]} toString [To stringify the the string or not]
 */
const handelSetValue = (_key, _value, toString = true) => {
  try {
    if (window.localStorage && _value !== undefined) {
      if (toString) {
        window.localStorage.setItem(_key, JSON.stringify(_value));
      } else {
        window.localStorage.setItem(_key, _value);
      }
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * [remove value from the localstorage]
 * @param {[String]} key  [Name of the key]
 * @param {[String|Number|Object|Bool]} value [Value you want to remove]
 */
const handleRemovevalue = (_key) => {
  if (window.localStorage) {
    window.localStorage.removeItem(_key);
  }
};

/**
 * [clear Removes "ALL" session storage keys and values]
 * @return {[type]} [description]
 */
function handleClear() {
  if (window.localStorage) {
    window.localStorage.clear();
  }
}

const oNoLocalStorage = {
  clear: handleClear,
  set: handelSetValue,
  get: handelGetValue,
  remove: handleRemovevalue,
};
export default oNoLocalStorage;
