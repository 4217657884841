import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/commons/page-header";
import { Button } from "../../components/ui";
import { LOGGEDIN } from "../../constants/storage-keys";
import { handelLogout } from "../../reducers/authSlice";
import defultProfilePic from "../../assets/images/single_bill.svg";
import { TRootState } from "../../store";
import call from "../../assets/images/call.svg";
import market from "../../assets/images/mandi.svg";
import logout from "../../assets/images/logout.svg";
import { useContext, useEffect } from "react";
import { deviceInfoContext } from "../../context";
import PageMainHeader from "../../components/commons/page-main-header";
import useGetByIdDocs from "../login/useGetByIdDocs";
import DocumentPreViewerer from "../orders/components/document-viewer";
import { FaEdit } from "react-icons/fa";
import useKycDocs from "../login/useKycDocs";
import verify from "../../assets/images/verify.svg";
const MyProfile = () => {
  const { width } = useContext(deviceInfoContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { name, tradeName, tradeType, mobile } = useSelector(
    (state: TRootState) => ({
      name: state.auth.name,
      tradeName: state.auth.tradeName,
      tradeType: state.auth.tradeType,
      mobile: state.auth.mobile,
    })
  );
  const logOut = () => {
    localStorage.clear();
    dispatch(handelLogout(null));
    navigate("/");
  };

  const { docs, setDocs } = useGetByIdDocs();
  const { getKycDocs, setIsVerified, isVerified } = useKycDocs({});

  useEffect(() => {
    getKycDocs();
  }, []);

  return (
    <>
      <PageMainHeader />
      <PageHeader
        showBackButton={true}
        showTitle={true}
        title={`My Profile`}
        topVal="3.7rem"
      />
      <div
        className="p-4"
        style={{ height: "calc(100vh - 12rem)", overflowY: "auto" }}
      >
        <div className="bg-light-primary px-3 py-2 mt-2 rounded-t-md">
          <span className="text-sm">Personal Details</span>
        </div>
        <div className="bg-white px-3 py-4 border rounded-b-md">
          <div className="flex mb-3 gap-2">
            <img src={defultProfilePic} className="w-8" />
            <span className="flex flex-col">
              <span className="color-sub-text text-xs">Full Name</span>
              <span className="font-medium text-sm">{name}</span>
            </span>
          </div>
          <div className="flex gap-2">
            <img src={call} className="w-8" />
            <span className="flex flex-col">
              <span className="color-sub-text text-xs">Contact Number</span>
              <span className="font-medium text-sm">{mobile}</span>
            </span>
          </div>
        </div>
        <div className="bg-light-primary px-3 py-2 mt-3 rounded-t-md">
          <span className="text-sm">Trade Details</span>
        </div>
        <div className="bg-white px-3 py-4 border rounded-b-md">
          <div className="flex gap-2 items-start">
            <img src={market} className="w-8" />
            <span>
              {" "}
              <span className="flex flex-col mb-3">
                <span className="color-sub-text text-xs">Trade Name</span>
                <span className="font-medium text-sm">{tradeName}</span>
              </span>
              <span className="flex flex-col">
                <span className="color-sub-text text-xs">Trade Type</span>
                <span className="font-medium text-sm">{tradeType}</span>
              </span>
            </span>
          </div>
        </div>

        <div className="bg-light-primary px-3 mt-3 py-2 rounded-t-md flex justify-between items-center">
          <div className="flex items-center">
            <span className="text-sm">Documents</span>
            {/* <span>
              {isVerified ? (
                <div className="flex px-4">
                  <img src={verify} className="w-5 h-5" />
                  <span className="text-sm color-sub-text">Verified</span>
                </div>
              ) : (
                ""
              )}
            </span> */}
          </div>
          {docs?.status == "BLOCKED" || docs?.status == "REJECTED" ? (
            ""
          ) : (
            <div className="">
              <div
                onClick={() => {
                  navigate("/kyc-verification/edit");
                }}
                className="cursor-pointer"
              >
                <span className="text-green-600">EDIT</span>
              </div>
            </div>
          )}
        </div>
        <div className="bg-white px-3 py-4 border rounded-b-md">
          <div className="">
            <span className="flex flex-col mb-1">
              <span className="color-sub-text text-xs">Aadhar Card</span>
            </span>
            <div className="flex overflow-auto">
              {docs?.kycDocs &&
              docs.kycDocs.filter((doc) => doc.name === "AADHAR").length !==
                0 ? (
                docs.kycDocs
                  .filter((doc) => doc.name === "AADHAR")
                  .map((image, i) => (
                    <div
                      key={i}
                      className="shrink-0 overflow-hidden border-2 p-2 rounded flex m-2"
                      style={{
                        width: "33.333%",
                        height: "10rem",
                      }}
                    >
                      <a href={image.url} target="_blank">
                        <DocumentPreViewerer
                          url={image.url}
                          docType={image.url.includes(".pdf") ? "pdf" : "image"}
                        />
                      </a>
                    </div>
                  ))
              ) : (
                <div
                  className="border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                  style={{
                    width: "33.333%",
                    height: "10rem",
                  }}
                >
                  <span className="text-xs">Not Uploaded Aadhar</span>
                </div>
              )}
            </div>
          </div>

          <div className="border-b my-2"></div>

          <div>
            <span className="flex flex-col mb-1">
              <span className="color-sub-text text-xs">
                Permanent Account Number(PAN)
              </span>
            </span>
            <div className="flex overflow-auto">
              {docs?.kycDocs &&
              docs.kycDocs.filter((doc) => doc.name === "PAN").length !== 0 ? (
                docs.kycDocs
                  .filter((doc) => doc.name === "PAN")
                  .map((image, i) => (
                    <div
                      key={i}
                      className="shrink-0 overflow-hidden border-2 p-2 rounded flex m-2"
                      style={{
                        width: "33.333%",
                        height: "10rem",
                      }}
                    >
                      <a href={image.url} target="_blank">
                        <DocumentPreViewerer
                          url={image.url}
                          docType={image.url.includes(".pdf") ? "pdf" : "image"}
                        />
                      </a>
                    </div>
                  ))
              ) : (
                <div
                  className="border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                  style={{
                    width: "33.333%",
                    height: "10rem",
                  }}
                >
                  <span className="text-xs">Not Uploaded PAN</span>
                </div>
              )}
            </div>
          </div>

          <div className="border-b my-2"></div>

          <div>
            <span className="flex flex-col mb-1">
              <span className="color-sub-text text-xs">Bank Statement</span>
            </span>
            <div className="flex overflow-auto">
              {docs?.kycDocs &&
              docs.kycDocs.filter((doc) => doc.name === "BANK_STATEMENT")
                .length !== 0 ? (
                docs.kycDocs
                  .filter((doc) => doc.name === "BANK_STATEMENT")
                  .map((image, i) => (
                    <div
                      key={i}
                      className="shrink-0 overflow-hidden border-2 p-2 rounded flex m-2"
                      style={{
                        width: "33.333%",
                        height: "10rem",
                      }}
                    >
                      <a href={image.url} target="_blank">
                        <DocumentPreViewerer
                          url={image.url}
                          docType={image.url.includes(".pdf") ? "pdf" : "image"}
                        />
                      </a>
                    </div>
                  ))
              ) : (
                <div
                  className="border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                  style={{
                    width: "33.333%",
                    height: "10rem",
                  }}
                >
                  <span className="text-xs">Not Uploaded Bank_Statement</span>
                </div>
              )}
            </div>
          </div>

          <div className="border-b my-2"></div>

          <div>
            <span className="flex flex-col mb-1">
              <span className="color-sub-text text-xs">ITR Document</span>
            </span>
            <div className="flex overflow-auto">
              {docs?.kycDocs &&
              docs.kycDocs.filter((doc) => doc.name === "ITR").length !== 0 ? (
                docs.kycDocs
                  .filter((doc) => doc.name === "ITR")
                  .map((image, i) => (
                    <div
                      key={i}
                      className="shrink-0 overflow-hidden border-2 p-2 rounded flex m-2"
                      style={{
                        width: "33.333%",
                        height: "10rem",
                      }}
                    >
                      <a href={image.url} target="_blank">
                        <DocumentPreViewerer
                          url={image.url}
                          docType={image.url.includes(".pdf") ? "pdf" : "image"}
                        />
                      </a>
                    </div>
                  ))
              ) : (
                <div
                  className="border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                  style={{
                    width: "33.333%",
                    height: "10rem",
                  }}
                >
                  <span className="text-xs">Not Uploaded ITR</span>
                </div>
              )}
            </div>
          </div>
          <div className="border-b my-2"></div>
          <div>
            <span className="flex flex-col mb-1">
              <span className="color-sub-text text-xs">
                Trade/Mandi License
              </span>
            </span>
            <div className="flex overflow-auto">
              {docs?.kycDocs &&
              docs.kycDocs.filter((doc) => doc.name === "TRADE_LICENSE")
                .length !== 0 ? (
                docs.kycDocs
                  .filter((doc) => doc.name === "TRADE_LICENSE")
                  .map((image, i) => (
                    <div
                      key={i}
                      className="shrink-0 overflow-hidden border-2 p-2 rounded flex m-2"
                      style={{
                        width: "33.333%",
                        height: "10rem",
                      }}
                    >
                      <a href={image.url} target="_blank">
                        <DocumentPreViewerer
                          url={image.url}
                          docType={image.url.includes(".pdf") ? "pdf" : "image"}
                        />
                      </a>
                    </div>
                  ))
              ) : (
                <div
                  className="border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                  style={{
                    width: "33.333%",
                    height: "10rem",
                  }}
                >
                  <span className="text-xs">
                    Not Uploaded Trade/Mandi License
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className="border-b my-2"></div>
          <div>
            <span className="flex flex-col mb-1">
              <span className="color-sub-text text-xs">
                {" "}
                Empty Invoice/Business Card
              </span>
            </span>
            <div className="flex overflow-auto">
              {docs?.kycDocs &&
              docs.kycDocs.filter((doc) => doc.name === "INVOICE").length !==
                0 ? (
                docs.kycDocs
                  .filter((doc) => doc.name === "INVOICE")
                  .map((image, i) => (
                    <div
                      key={i}
                      className="shrink-0 overflow-hidden border-2 p-2 rounded flex m-2"
                      style={{
                        width: "33.333%",
                        height: "10rem",
                      }}
                    >
                      <a href={image.url} target="_blank">
                        <DocumentPreViewerer
                          url={image.url}
                          docType={image.url.includes(".pdf") ? "pdf" : "image"}
                        />
                      </a>
                    </div>
                  ))
              ) : (
                <div
                  className="border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                  style={{
                    width: "33.333%",
                    height: "10rem",
                  }}
                >
                  <span className="text-xs">
                    Not Uploaded Invoice/Business Card
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="w-full absolute bottom-0 py-2 border-t bg-white"
        style={{ width: width }}
      >
        <Button
          className="mx-auto"
          shape="round"
          variant="red"
          onClick={() => {
            logOut();
          }}
        >
          <img src={logout} className="pr-2" />
          Logout
        </Button>
      </div>
    </>
  );
};
export default MyProfile;
