import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import useAxiosClient from "../../hooks/useAxiosClient";
import { TTraderDocs } from "../../types";
import { TRootState } from "../../store";
import { setKycDocsInfo, setSrcBusinessAcc } from "../../reducers/authSlice";
const useGetByIdDocs = () => {
  const { handleGetCall } = useAxiosClient();

  const [docs, setDocs] = useState<TTraderDocs | null>();

  const { traderId } = useSelector((state: TRootState) => ({
    traderId: state.auth.traderId,
  }));
  const dispatch = useDispatch();
  useEffect(() => {
    handleGetCall<TTraderDocs>(
      `account/mandi/portal/account/getKycDocs/traderId${traderId}`
    ).then(({ data }) => {
      if (data) {
        setDocs(data);
        dispatch(setKycDocsInfo(data));
        dispatch(setSrcBusinessAcc(data.bsnsAccnt[0].id));
      } else {
        setDocs(null);
      }
    });
  }, []);

  return { docs, setDocs };
};
export default useGetByIdDocs;
