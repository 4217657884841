import { FC, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import { FaBell } from "react-icons/fa6";
import { deviceInfoContext } from "../../../context";
import logo from "../../../assets/images/mandi-logo.svg";
import DefultLogo from "../../../assets/images/single_bill.svg";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "../../../store";
import getTradeStatus from "../../../helpers/getTradeStatus";
import { updatePaymentUpdateStatus } from "../../../reducers/tradeSlice";
const PageHeader: FC<{
  title?: string;
  showBackButton?: boolean;
  showTitle?: boolean;
  showUserInfo?: boolean;
  topVal?: string;
  fromScreen?: string;
  subText?: string;
}> = ({
  title,
  showBackButton,
  showTitle,
  showUserInfo,
  topVal,
  fromScreen,
  subText,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useContext(deviceInfoContext);
  const { name, traderId, tradeType, loggedin } = useSelector(
    (state: TRootState) => ({
      name: state.auth.name,
      traderId: state.auth.traderId,
      tradeType: state.auth.tradeType,
      loggedin: state.auth.loggedin,
    })
  );

  const back = () => {
    if (showTitle === true && fromScreen === "View Trade") {
      navigate("/");
    } else if (fromScreen === "PaymentHistory") {
      navigate(-1);
      dispatch(updatePaymentUpdateStatus({ paymentUpdateStatus: false }));
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <div
        className="bg-white shadow-md flex items-center pr-2 font-semibold fixed z-10"
        style={{ height: "3.8rem", width: width, top: topVal }}
      >
        {showBackButton === true && (
          <div
            className="pr-3 pl-2 cursor-pointer h-full flex"
            onClick={() => back()}
          >
            <FaArrowLeft className="mt-auto mb-auto" />
          </div>
        )}
        {showTitle === true ? (
          <div className="px-2 flex flex-col">
            <span>{title}</span>
            {tradeType === "DEST" && (
              <span className="color-sub-text text-xs font-medium">
                {/* {getTradeStatus(subText)} */}
              </span>
            )}
          </div>
        ) : showUserInfo === true ? (
          <div
            className="flex gap-2 cursor-pointer pl-2"
            onClick={() => navigate("/my-profile")}
          >
            <img src={DefultLogo} className="w-8 h-8" />
            <div className="flex flex-col text-xs">
              <span>{name}</span>
              <span>{`User Id :  ${traderId}`}</span>
            </div>
          </div>
        ) : (
          <></>
        )}
        {/* Always render the logo, regardless of loggedin state */}
        <span className="ml-auto">
          <img src={logo} className="w-16 px-1" />
        </span>
      </div>
      {/* Add height spacer for fixed header */}
      <div style={{ height: "3.8rem" }}></div>
    </>
  );
};
export default PageHeader;