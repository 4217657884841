import { BsCheckCircleFill, BsDoorClosedFill } from "react-icons/bs";
import accepteed_state_active from "../assets/images/accepted_state_active.svg";
import pending_state from "../assets/images/pending_state_active.svg";
import arrival_active from "../assets/images/arrival_active.svg";
import patti_active from "../assets/images/patti_active.svg";
import pay_pnd_Active from "../assets/images/pay_pnd_Active.svg";
import pay_com_active from "../assets/images/pay_com_active.svg";
import closed_active from "../assets/images/closed_active.svg";
import pen from "../assets/images/pen.svg";
import cancel from "../assets/images/cancel_sm.svg";
export function tradeStatusIcon(status) {
  let tradeStatus = status;

  switch (status) {
    case "PENDING":
      return (
        <div className="relative">
          <img src={pending_state} className="w-8" />
          <img src={pen} className="selectedIconTick w-3" />
        </div>
      );
      break;
    case "ACCEPTED":
      return (
        <div className="relative">
          <img src={accepteed_state_active} className="w-8" />
          <BsCheckCircleFill className="selectedIconTick" />
        </div>
      );
      break;
    case "CANCEL":
      return (
        <div className="relative">
          <img src={pending_state} className="w-8" />
          <img src={cancel} className="selectedIconTick w-3" />
        </div>
      );
      break;
    case "ARL_CONFIRMED":
      return (
        <div className="relative">
          <img src={arrival_active} className="w-8" />
          <BsCheckCircleFill className="selectedIconTick" />
        </div>
      );
      break;
    case "PATTI_FINALIZED":
      return (
        <div className="relative">
          <img src={patti_active} className="w-8" />
          <BsCheckCircleFill className="selectedIconTick" />
        </div>
      );
      break;
    case "PMT_PENDING":
      return (
        <div className="relative">
          <img src={pay_pnd_Active} className="w-8" />
          <img src={pen} className={"selectedIconTick"} />
        </div>
      );
      break;
    case "PAYMENT":
      return (
        <div className="relative">
          <img src={pay_com_active} className="w-8" />
          <BsCheckCircleFill className="selectedIconTick" />
        </div>
      );
      break;
    case "PMT_COMPLETED":
      return (
        <div className="relative">
          <img src={pay_com_active} className="w-8" />
          <BsCheckCircleFill className="selectedIconTick" />
        </div>
      );
      break;
    case "CANCEL":
      tradeStatus = "Trade Cancelled";
      break;
    case "CLOSED":
      return (
        <div className="relative">
          <img src={closed_active} className="w-8" />
          <BsCheckCircleFill className="selectedIconTick" />
        </div>
      );
      break;
  }
  //   return tradeStatus;
}
export default tradeStatusIcon;
