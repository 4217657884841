import { FC, useContext, useEffect, useState } from "react";
import { deviceInfoContext } from "../../../context";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "../../../store";
import trades from "../../../assets/images/menu/trades.svg";
import trades_y from "../../../assets/images/menu/trades_y.svg";
import reports from "../../../assets/images/menu/reports.svg";
import reports_y from "../../../assets/images/menu/reports_y.svg";
import classes from "./style.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
const BottomMenu: FC<{
  title?: string;
}> = ({ title }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useContext(deviceInfoContext);
  const { loggedin } = useSelector((state: TRootState) => ({
    loggedin: state.auth.loggedin,
  }));
  const [activeTab, setActiveTab] = useState("trades");

  const onClickTab = (tab: string) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    console.log(location.pathname);
    if (location.pathname == "/reports") {
      setActiveTab("reports");
    } else {
      setActiveTab("trades");
    }
  }, []);
  return (
    <>
      <div>
        {loggedin == true && (
          <div
            className="absolute bottom-0"
            style={{ width: width, zIndex: "999" }}
          >
            <div
              className={`${classes.menubar} flex justify-around items-center bg-primary p-3`}
            >
              <span
                className={`text-md inline-block cursor-pointer ${
                  activeTab === "trades"
                    ? classes.activeTab
                    : classes.colorwhite
                }`}
                style={{ whiteSpace: "nowrap" }}
                onClick={() => {
                  onClickTab("trades");
                  navigate("/");
                }}
              >
                <span className="flex flex-col items-center text-sm">
                  <img src={activeTab === "trades" ? trades_y : trades} />
                  <span>Trades</span>
                </span>
              </span>
              <span
                className={`text-md pt-0 inline-block cursor-pointer ml-2 ${
                  activeTab === "reports"
                    ? classes.activeTab
                    : classes.colorwhite
                }`}
                style={{ whiteSpace: "nowrap" }}
                onClick={() => {
                  onClickTab("reports");
                  navigate("/reports");
                }}
              >
                <span className="flex flex-col items-center text-sm">
                  <img src={activeTab === "reports" ? reports_y : reports} />
                  <span>Reports</span>
                </span>
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default BottomMenu;
