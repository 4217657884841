import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../constants";
import { TRootState } from "../store";
import { useDispatch, useSelector } from "react-redux";
import oNoLocalStorage from "../helpers/oNoLocalStorage";
import { handelLogout } from "../reducers/authSlice";
const useAxiosClient = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /** Setting common axios header  */
  const traderId = "1";
  const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
  });
  const { id, secret, isAuthenticated } = useSelector((state: TRootState) => ({
    id: state.auth.clientId,
    isAuthenticated: state.auth.loggedin,
    secret: state.auth.clientSecret,
  }));

  /** Setting common axios header  */
  const clientID = localStorage.getItem("clientId") || "";
  const clientSecret = localStorage.getItem("clientSecret") || "";

  axiosInstance.defaults.headers.common["Content-Type"] = "application/json";
  axiosInstance.defaults.headers.common["clientId"] = clientID;
  axiosInstance.defaults.headers.common["clientSecret"] = clientSecret;
  /** Function to logout the user and navigate to login page */
  const handleLogout = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    localStorage.clear();
    dispatch(handelLogout(null));
    navigate("/");
    window.location.reload();
    //@ts-ignore
    // oNoLocalStorage.set("LinkPath", "/smartboard", false);
    // //@ts-ignore
    // oNoLocalStorage.set("statusPlan", "FAILURE", false);
    // //@ts-ignore
    // oNoLocalStorage.set("LinkId", "1", false);
    //@ts-ignore
    oNoLocalStorage.set("isauth", false, false);
  };
  /** Function to attach click id in URL */
  const handleCreateURL = (_url = "") => {
    let newURL = _url;
    if (_url.includes(":traderId")) {
      newURL = _url.replace(":traderId", traderId);
      return newURL;
    }

    if (_url.includes(":traderId")) {
      newURL = _url.replace(":traderId", traderId);
    }

    return newURL;
  };
  const handleGetCall = <T>(url: string): Promise<{ data: T }> => {
    return new Promise((resolve, reject) => {
      const apiURL = handleCreateURL(url);
      axiosInstance
        .get(`${apiURL}`)
        .then(({ data }) => {
          resolve({ data: data.data });
        })
        .catch((error: any) => {
          if (
            error.response.data &&
            error.response.data.status.message === "INVALID_CREDENTIALS"
          ) {
            handleLogout();
          }
          reject(error);
        });
    });
  };
  const handlePostCall = <T>({
    URL = "",
    apiParams = {},
    config = {},
  }): Promise<{ data: T; status: any }> => {
    return new Promise((resolve, reject) => {
      const apiURL = handleCreateURL(URL);
      axiosInstance
        .post(`${apiURL}`, apiParams, config)
        .then(({ data }) => {
          resolve({ data: data.data, status: data.status });
        })
        .catch((error: any) => {
          console.log(error, "error");
          if (error.response.data.status.message === "INVALID_CREDENTIALS") {
            handleLogout();
          }
          reject(error);
        });
    });
  };
  const handlePutCall = <T>({
    URL = "",
    apiParams = {},
  }): Promise<{ data: T; status: any }> => {
    return new Promise((resolve, reject) => {
      const apiURL = handleCreateURL(URL);
      axiosInstance
        .put(`${apiURL}`, apiParams)
        .then(({ data }) => {
          resolve({ data: data.data, status: data.status });
        })
        .catch((error: any) => {
          if (error.response.data.status.message === "INVALID_CREDENTIALS") {
            handleLogout();
          }
          reject(error);
        });
    });
  };
  return {
    handlePutCall,
    handleGetCall,
    handlePostCall,
  };
};

export default useAxiosClient;
