import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import {
  TCrop,
  TDestinationPartner,
  TPaymentHistory,
  TProgressStatus,
  TProgressStatusObj,
  TTradeCompleteDetai,
  TTradeDetails,
  TTradeUpdateDetais,
} from "../types";
const Storeid = localStorage.getItem("tradeIdLocal") || "";
type TinitialState = {
  tradeDetails: TTradeCompleteDetai | null;
  tradeEditStatus: boolean;
  tradeId: string | null;
  partyData: TDestinationPartner[];
  selectedParty: TDestinationPartner | null;
  selectedDate: string;
  selectedCrop: TCrop | null;
  deletedCrop: TCrop | null;
  tradeUpdateDetails: TTradeUpdateDetais | null;
  selectedTime: string;
  progressStatus: TProgressStatusObj | null;
  allTrades: TTradeDetails[];
  paymentUpdateStatus: boolean;
  paymentHistoryObj: TPaymentHistory | null;
  paymentId: number;
  allReports: TTradeDetails[];
  allCustomReports: TTradeDetails[];
};
const initialState: TinitialState = {
  tradeDetails: null,
  tradeEditStatus: false,
  tradeId: Storeid,
  partyData: [],
  selectedParty: null,
  selectedDate: moment().format("YYYY-MM-DD"),
  selectedCrop: null,
  deletedCrop: null,
  tradeUpdateDetails: null,
  selectedTime: "",
  progressStatus: null,
  allTrades: [],
  paymentUpdateStatus: false,
  paymentHistoryObj: null,
  paymentId: 0,
  allReports: [],
  allCustomReports: [],
};
const tradeSlice = createSlice({
  name: "trades",
  initialState: initialState,
  reducers: {
    updateTradeDetails: (
      state,
      action: PayloadAction<{
        tradeDetails: TTradeCompleteDetai | null;
      }>
    ) => {
      state.tradeDetails = action.payload.tradeDetails;
    },
    editTrade: (
      state,
      action: PayloadAction<{
        tradeEditStatus: boolean;
      }>
    ) => {
      state.tradeEditStatus = action.payload.tradeEditStatus;
    },
    updateTradeId: (
      state,
      action: PayloadAction<{
        tradeId: string | null;
      }>
    ) => {
      state.tradeId = action.payload.tradeId;
    },
    updatePartyData: (
      state,
      action: PayloadAction<{
        partyData: TDestinationPartner[];
      }>
    ) => {
      state.partyData = action.payload.partyData;
    },
    updateCropData: (
      state,
      action: PayloadAction<{
        selectedCrop: TCrop | null;
        selectedParty?: TDestinationPartner | null;
        deletedCrop?: TCrop | null;
      }>
    ) => {
      // if (action.payload.selectedCrop) {
      state.selectedCrop = action.payload.selectedCrop;
      // }

      if (action.payload.selectedParty != null) {
        state.selectedParty = action.payload.selectedParty;
      }
      if (action.payload.deletedCrop) {
        state.deletedCrop = action.payload.deletedCrop;
      }
    },
    setSelectedDate: (state, action) => {
      console.log(action.payload, "action.payload");
      state.selectedDate = action.payload;
    },
    arrivalTradeDetails: (
      state,
      action: PayloadAction<{
        tradeUpdateDetails: TTradeUpdateDetais | null;
      }>
    ) => {
      state.tradeUpdateDetails = action.payload.tradeUpdateDetails;
    },
    setSelectedTime: (state, action) => {
      state.selectedTime = action.payload;
    },
    updateProgressDetails: (
      state,
      action: PayloadAction<{
        progressStatus: TProgressStatusObj | null;
      }>
    ) => {
      state.progressStatus = action.payload.progressStatus;
    },
    updateAllTrades: (
      state,
      action: PayloadAction<{
        allTrades: TTradeDetails[];
      }>
    ) => {
      state.allTrades = action.payload.allTrades;
    },
    updatePaymentUpdateStatus: (
      state,
      action: PayloadAction<{
        paymentUpdateStatus: boolean;
      }>
    ) => {
      state.paymentUpdateStatus = action.payload.paymentUpdateStatus;
    },
    getPaymentHistory: (
      state,
      action: PayloadAction<{
        paymentHistoryObj: TPaymentHistory | null;
      }>
    ) => {
      state.paymentHistoryObj = action.payload.paymentHistoryObj;
    },
    updatePayId: (
      state,
      action: PayloadAction<{
        paymentId: number;
      }>
    ) => {
      state.paymentId = action.payload.paymentId;
    },
    updateAllReports: (
      state,
      action: PayloadAction<{
        allReports: TTradeDetails[];
      }>
    ) => {
      state.allReports = action.payload.allReports;
    },
    updateCustomReports: (
      state,
      action: PayloadAction<{
        allCustomReports: TTradeDetails[];
      }>
    ) => {
      state.allCustomReports = action.payload.allCustomReports;
    },
  },
});

export const {
  updateTradeDetails,
  editTrade,
  updateTradeId,
  updatePartyData,
  setSelectedDate,
  updateCropData,
  arrivalTradeDetails,
  setSelectedTime,
  updateProgressDetails,
  updateAllTrades,
  updatePaymentUpdateStatus,
  getPaymentHistory,
  updatePayId,
  updateAllReports,
  updateCustomReports,
} = tradeSlice.actions;
export default tradeSlice.reducer;
