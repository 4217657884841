import { FC, ReactNode, useMemo } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { deviceInfoContext } from "../context";
import banner1 from "../assets/images/banner1.png";
import banner2 from "../assets/images/banner2.png";
import banner3 from "../assets/images/banner3.png";
import classes from "./style.module.scss";
import { Slider } from "../components/ui";
import { useState } from "react";
import { useEffect } from "react";
function getdeviceInfo(): {
  deviceType: "MOBILE" | "DESKTOP" | "";
  width: string;
  height: string;
  left: string;
  right: string;
} {
  let width = window.innerWidth;
  let height = window.innerHeight;
  let deviceType: "MOBILE" | "DESKTOP" = width <= 800 ? "MOBILE" : "DESKTOP";
  return {
    deviceType: deviceType,
    width: deviceType === "MOBILE" ? width + "px" : "500px",
    height: deviceType === "MOBILE" ? width + "px" : height + "px",
    left: "0px",
    right: "500px",
  };
}
let installPrompt: any = null;

const DeviceLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const deviceinfo = useMemo(() => {
    return getdeviceInfo();
  }, []);
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (deviceinfo.deviceType === "DESKTOP") {
      if (step == 0) {
        setTimeout(() => {
          setStep(1);
        }, 6000);
      } else if (step == 1) {
        setTimeout(() => {
          setStep(2);
        }, 6000);
      } else {
        setTimeout(() => {
          setStep(0);
        }, 6000);
      }
    }
  }, [step]);
  useEffect(() => {
    // const installButton = document.querySelector("#install");
    // window.addEventListener("beforeinstallprompt", (event) => {
    //   event.preventDefault();
    //   installPrompt = event;
    // });
  }, []);
  if (deviceinfo.deviceType === "MOBILE") {
    return (
      <deviceInfoContext.Provider value={deviceinfo}>
        {children}
        <ToastContainer position="top-center" />
      </deviceInfoContext.Provider>
    );
  }

  return (
    <deviceInfoContext.Provider value={deviceinfo}>
      <div className="flex">
        <div
          className="border"
          style={{
            width: deviceinfo.width,
            height: deviceinfo.height,
            background: "white",
            boxShadow: "#dcdedc -10px 0px 10px 7px",
            overflow: "auto",
          }}
        >
          {children}
          <ToastContainer position="top-left" />
        </div>
        <div
          style={{
            width: `calc(100% - ${deviceinfo.width})`,
          }}
        >
          <Slider step={step}>
            <div>
              <img
                src={banner1}
                alt="image"
                className="w-full"
                style={{ height: "100vh" }}
              />
            </div>
            <div>
              <img
                src={banner2}
                alt="image"
                className="w-full"
                style={{ height: "100vh" }}
              />
            </div>
            <div>
              <img
                src={banner3}
                alt="image"
                className="w-full"
                style={{ height: "100vh" }}
              />
            </div>
          </Slider>
        </div>
      </div>
    </deviceInfoContext.Provider>
  );
};
export default DeviceLayout;
