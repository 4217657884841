export function tradeStatusText(status) {
  let tradeStatus = status;

  switch (status) {
    case "PENDING":
      tradeStatus = "Approval Pending";
      break;
    case "ACCEPTED":
      tradeStatus = "Trade Accepted";
      break;
    case "CANCEL":
      tradeStatus = "Cancelled";
      break;
    case "ARL_CONFIRMED":
      tradeStatus = "Arrival Confirmed";
      break;
    case "PATTI_FINALIZED":
      tradeStatus = "Patti Finalized";
      break;
    case "PMT_PENDING":
      tradeStatus = "Payment Pending";
      break;
    case "PAYMENT":
      tradeStatus = "Payment Completed";
      break;
    case "PMT_COMPLETED":
      tradeStatus = "Payment Completed";
      break;
    case "CANCEL":
      tradeStatus = "Trade Cancelled";
      break;
    case "CLOSED":
      tradeStatus = "Trade Closed";
      break;
  }
  return tradeStatus;
}
export default tradeStatusText;
