import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LOGGEDIN, LOGGEDIN_USER_INFO } from "../constants/storage-keys";
import { TCrop, TDestinationPartner, TTraderDocs, TTraderOtp } from "../types";
const userinfo = JSON.parse(
  localStorage.getItem(LOGGEDIN_USER_INFO) || JSON.stringify({})
);
type TinitialState = {
  loggedin: boolean;
  otpReqId: string;
  traderId: number;
  regStatus: string;
  name: string;
  tradeName: string;
  tradeType: string;
  mobile: string;
  otp: TTraderOtp | null;
  allCrops: Array<TCrop>;
  clientId: string;
  clientSecret: string;
  destBusinessId: number;
  srcBusinessId: number;
  seconds: number;
  kycDocsInfo: TTraderDocs | null;
};
const initialState: TinitialState = {
  loggedin: localStorage.getItem(LOGGEDIN) === "1" ? true : false,
  otpReqId: "",
  traderId: userinfo.traderId || 0,
  regStatus: userinfo.regStatus || "",
  name: userinfo.name || "",
  tradeName: userinfo.tradeName || "",
  tradeType: userinfo.tradeType || "",
  mobile: userinfo.mobile || "",
  allCrops: [],
  otp: null,
  destBusinessId: 0,
  srcBusinessId: 0,
  clientId: userinfo?.clientId,
  clientSecret: userinfo?.clientSecret,
  seconds: 0,
  kycDocsInfo: null,
};
const authSlice = createSlice({
  name: "authentication",
  initialState: initialState,
  reducers: {
    setLoggedinStatus: (
      state,
      action: PayloadAction<{ loggedin: boolean; userInfo?: any }>
    ) => {
      state.loggedin = action.payload.loggedin;
    },
    handelLogout: (state, action) => {
      state.loggedin = false;
      state.traderId = 0;
      state.regStatus = "";
    },

    setSiignInData: (
      state,
      action: PayloadAction<{
        otpReqId: string;
        traderId: number;
      }>
    ) => {
      state.otpReqId = action.payload.otpReqId;
      state.traderId = action.payload.traderId;
    },

    setRequestId: (state, action: PayloadAction<TTraderOtp>) => {
      state.otp = action.payload;
    },
    otpValidationData: (
      state,
      action: PayloadAction<{
        regStatus: string;
        traderId: number;
        name: string;
        tradeName: string;
        tradeType: string;
        mobile: string;
      }>
    ) => {
      state.regStatus = action.payload.regStatus;
      state.traderId = action.payload.traderId;
      state.name = action.payload.name;
      state.tradeName = action.payload.tradeName;
      state.tradeType = action.payload.tradeType;
      state.mobile = action.payload.mobile;
    },
    setAllCrops: (state, action: PayloadAction<TCrop[]>) => {
      state.allCrops = action.payload;
    },
    setDestBusinessAcc: (state, action: PayloadAction<number>) => {
      state.destBusinessId = action.payload;
    },
    setSrcBusinessAcc: (state, action: PayloadAction<number>) => {
      state.srcBusinessId = action.payload;
    },

    setSeconds: (state, action: PayloadAction<number>) => {
      state.seconds = action.payload;
    },
    setKycDocsInfo: (state, action: PayloadAction<TTraderDocs>) => {
      state.kycDocsInfo = action.payload;
    },
  },
});

export const {
  setLoggedinStatus,
  setSiignInData,
  otpValidationData,
  handelLogout,
  setAllCrops,
  setDestBusinessAcc,
  setSrcBusinessAcc,
  setSeconds,
  setKycDocsInfo,
} = authSlice.actions;
export default authSlice.reducer;
