import { FC, ReactElement } from "react";

const Slider: FC<{
  children: ReactElement[];
  step: number;
  fullHeight?: boolean;
}> = ({ children, step, fullHeight = false }) => {
  let fullHeightClass = fullHeight ? "h-full" : "";
  return (
    <div className={`w-full ${fullHeightClass} overflow-hidden`}>
      <div
        className={`flex ${fullHeightClass}`}
        style={{
          transition: "transform 0.6s ease 0s",
          transform: `translateX(${step * -100}%)`,
        }}
      >
        {children.map((child, i) => (
          <div
            key={`slider-${i}`}
            className={`w-full shrink-0 ${fullHeightClass}`}
          >
            {child}
          </div>
        ))}
      </div>
    </div>
  );
};
export default Slider;
