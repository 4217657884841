import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Compressor from "compressorjs";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosClient from "../../../hooks/useAxiosClient";
import { TRootState } from "../../../store";
import { TCrop, TTradeCompleteDetai, TTradeDocument } from "../../../types";
import moment from "moment";
import {
  updateTradeDetails,
  updateTradeId,
} from "../../../reducers/tradeSlice";
import isArrivalDateStatus from "../../../helpers/getArrivalDateStatus";
import { getCurrencyNumberWithOutSymbol } from "../../../helpers/get-currency-number";
import { isPaymentDateBeforeArrivalStatus } from "../../../helpers/getPaymentDateStatus";
const useArrivalsView = (
  type: string,
  editStatus: boolean,
  closeForm?: any
) => {
  const params = useParams();
  const navigate = useNavigate();
  const { handlePutCall, handlePostCall } = useAxiosClient();
  const dispatch = useDispatch();
  const [alltradeDetail, setAllTradeDetail] =
    useState<TTradeCompleteDetai | null>(null);
  const {
    traderId,
    tradeDetails,
    arlDate,
    paymentHistoryObj,
    paymentUpdateStatus,
  } = useSelector((state: TRootState) => ({
    traderId: state.auth.traderId,
    tradeDetails: state.trades.tradeDetails,
    arlDate: state.trades.tradeDetails?.arlDate,
    paymentHistoryObj: state.trades.paymentHistoryObj,
    paymentUpdateStatus: state.trades.paymentUpdateStatus,
  }));
  const [billCreationStatus, setBillCreationStatus] = useState<{
    status: "" | "PENDING" | "COMPLETED" | "LOADING";
  }>({ status: "PENDING" });
  const [arrivalDocuments, setOtherDocuments] = useState<
    Array<{ docType: "image" | "pdf"; url: string }>
  >([]);
  const [pattiDocuments, setPattiDocuments] = useState<
    Array<{ docType: "image" | "pdf"; url: string }>
  >([]);
  const [paymentDocuments, setPaymentDocuments] = useState<
    Array<{ docType: "image" | "pdf"; url: string }>
  >([]);
  const [arrivalWeightDocuments, setArrivalWeightDocuments] = useState<
    Array<{ docType: "image" | "pdf"; url: string }>
  >([]);
  const [reqAdvDocuments, setReqAdvDocuments] = useState<
    Array<{ docType: "image" | "pdf"; url: string }>
  >([]);
  let otpTimer: any = null;
  const [isChecked, setIsChecked] = useState(false);
  const [termsAndConditionsModal, setTermsAndConditionsModal] = useState(false);
  const [otpVerificationModal, setOtpVerificationModal] = useState(false);
  const [sentOtpBtnText, setSendOtpButtonText] = useState("GET OTP");
  const [otpExpTime, setOtpExpTime] = useState(0);
  const checkHandler = () => {
    if (isChecked) {
      setIsChecked(!isChecked);
    } else {
      setTermsAndConditionsModal(true);
    }
    // setIsChecked(!isChecked);
  };
  const runOtpTimer = (timer: number) => {
    if (timer >= 0) {
      setOtpExpTime(timer);
      setTimeout(() => {
        runOtpTimer(timer - 1);
      }, 1000);
    } else {
      setSendOtpButtonText("RESEND OTP");
    }
  };
  const calculateAdvancePaid = () => {
    let sum = 0;
    if (alltradeDetail != null) {
      if (
        alltradeDetail?.pmtHistory.length > 0 &&
        alltradeDetail?.tradeType == "DEST"
      ) {
        for (let item of alltradeDetail?.pmtHistory) {
          if (
            item.pmtType.toUpperCase() == "ADVANCE" &&
            item.inOutWard?.toUpperCase() == "INWARD"
          ) {
            sum += item.amount;
          }
        }
      }
    }
    return sum;
  };
  const calculateAdvancePaidSrc = () => {
    let sum = 0;
    if (alltradeDetail != null) {
      if (
        alltradeDetail?.pmtHistory.length > 0 ||
        alltradeDetail?.tradeType == "SOURCE"
      ) {
        for (let item of alltradeDetail?.pmtHistory) {
          if (
            item.pmtType.toUpperCase() == "ADVANCE" &&
            item.inOutWard?.toUpperCase() == "OUTWARD"
          ) {
            sum += item.amount;
          }
        }
      }
    }
    return sum;
  };
  const [arrivalIInfo, setArrivalIInfo] = useState<{
    freightAmt: number | "";
    date: string;
    pattiAmt: number | "";
    comm: number | "";
    discount: number | "";
    lbrCharges: number | "";
    misc: number | "";
    totalPayable: number | "";
    totlaReceivable: number | "";
    payment: number | "";
    paidDate: string;
    arlTime: string;
    inwardPmt: number;
    balPayable: number;
    invAmt: number | "";
    freightPaid: number | "";
    balFreightAmt: number | "";
    weightAtSrc: number | "";
    destWeight: number | "";
    destWastage: number | "";
    netWeight: number | "";
    arlComments: string;
    pattiDate: string;
    pattiComments: string;
    commPerVal: number | "";
    finalPayable: number;
    srcPf: number;
    paymentMode: string | number;
    paymentComments: string;
    moistureLoss: number;
    avgRate: number;
    advancePaid: number;
    destRate: number | "";
    finalInvoice: number | "";
    rate: number | "";
    destDisc: number | "";
    srcDisc: number | "";
    destVehNum: string | "";
    cmdityCost: number | "";
    reqDate: string;
    finalReceivable: number;
  }>({
    freightAmt: tradeDetails?.freightAmt!,
    date:
      tradeDetails?.arlDate == "" || tradeDetails?.arlDate == null
        ? moment(new Date()).format("YYYY-MM-DD")
        : moment(new Date(tradeDetails?.arlDate!)).format("YYYY-MM-DD"),
    pattiAmt: tradeDetails?.pattiAmt || 0,
    comm: tradeDetails?.comm || 0,
    discount: tradeDetails?.discount || 0,
    lbrCharges: tradeDetails?.lbrCharges || 0,
    misc: tradeDetails?.misc || 0,
    totalPayable: tradeDetails?.totalPayable!,
    payment: paymentUpdateStatus
      ? paymentHistoryObj?.amount || 0
      : tradeDetails?.payment || 0,
    paidDate: paymentUpdateStatus
      ? moment(paymentHistoryObj?.date).format("YYYY-MM-DD")
      : moment(new Date()).format("YYYY-MM-DD"),
    arlTime:
      tradeDetails?.arlTime == "" || tradeDetails?.arlTime == null
        ? (new Date().getHours() < 10 ? "0" : "") +
          new Date().getHours() +
          ":" +
          (new Date().getMinutes() < 10 ? "0" : "") +
          new Date().getMinutes()
        : tradeDetails?.arlTime,
    inwardPmt: tradeDetails?.inwardPmt!,
    balPayable: tradeDetails?.balPayable!,
    invAmt: tradeDetails?.invAmt!,
    freightPaid: tradeDetails?.freightPaid || 0,
    // ? tradeDetails?.freightPaid!
    // : Number(tradeDetails?.freightAmt) - Number(tradeDetails?.freightAdv),
    balFreightAmt: tradeDetails?.freightAmt!,
    weightAtSrc: tradeDetails?.lineItems[0].weight!,
    destWeight: tradeDetails?.lineItems[0].destWeight || 0,
    destWastage: tradeDetails?.lineItems[0].add_wastage || 0,
    netWeight:
      (Number(tradeDetails?.lineItems[0].destWeight) > 0 &&
        Number(tradeDetails?.lineItems[0].destWeight) -
          Number(tradeDetails?.lineItems[0].add_wastage)) ||
      0,
    arlComments: tradeDetails?.arlComments! || "",
    pattiDate:
      tradeDetails?.pattiDate == "" || tradeDetails?.pattiDate == null
        ? moment(new Date()).format("YYYY-MM-DD")
        : moment(new Date(tradeDetails?.pattiDate!)).format("YYYY-MM-DD"),
    pattiComments: tradeDetails?.pattiComments! || "",
    commPerVal: tradeDetails?.commPerVal! || 0,
    finalPayable: tradeDetails?.finalPayable! || 0,
    srcPf: tradeDetails?.srcPf! || 0,
    paymentMode: paymentUpdateStatus
      ? paymentHistoryObj?.pmtMode || ""
      : tradeDetails?.paymentMode! || "",
    paymentComments: paymentUpdateStatus
      ? paymentHistoryObj?.comments || ""
      : tradeDetails?.paymentComments! || "",
    moistureLoss: tradeDetails?.moistureLoss! || 0,
    avgRate: tradeDetails?.avgRate || 0,
    advancePaid: calculateAdvancePaid() || 0,
    destRate: tradeDetails?.lineItems[0].destRate || 0,
    finalInvoice: tradeDetails?.invAmt || 0,
    rate: tradeDetails?.lineItems[0].rate || 0,
    totlaReceivable: tradeDetails?.totalReceivable || 0,
    destDisc: tradeDetails?.destDisc || 0,
    srcDisc: tradeDetails?.srcDisc || 0,
    destVehNum: tradeDetails?.destVehNum || "",
    cmdityCost: tradeDetails?.cmdityCost || 0,
    reqDate:
      tradeDetails?.reqAdv?.advDate == "" ||
      tradeDetails?.reqAdv?.advDate == null
        ? moment(new Date()).format("YYYY-MM-DD")
        : moment(new Date(tradeDetails?.reqAdv?.advDate!)).format("YYYY-MM-DD"),
    finalReceivable: tradeDetails?.finalReceivable || 0,
  });
  const [reqAdvInfo, setReqAdvInfo] = useState<{
    advLimit: number;
    requestAdv: number | "";
    advDate: string;
    comments: string;
    advDisb: number;
    advApproved: number;
    disbDate: string;
    status: number;
    srcOtp: string;
  }>({
    advLimit: tradeDetails?.reqAdv?.advLimit || 0,
    requestAdv: tradeDetails?.reqAdv?.requestAdv || 0,
    advDate:
      tradeDetails?.reqAdv?.advDate == "" ||
      tradeDetails?.reqAdv?.advDate == null
        ? moment(new Date()).format("YYYY-MM-DD")
        : moment(new Date(tradeDetails?.reqAdv?.advDate!)).format("YYYY-MM-DD"),
    comments: tradeDetails?.reqAdv?.comments || "",
    advDisb: tradeDetails?.reqAdv?.advDisb || 0,
    advApproved: tradeDetails?.reqAdv?.advApproved || 0,
    disbDate: tradeDetails?.reqAdv?.disbDate || "",
    status:
      tradeDetails?.reqAdv?.status == 1 ? 2 : tradeDetails?.reqAdv?.status || 1,
    srcOtp: "",
  });
  useEffect(() => {
    if (alltradeDetail == null) {
      setAllTradeDetail(tradeDetails);

      setAllTradeDetail({
        ...tradeDetails!,
        pattiAmt: arrivalIInfo.pattiAmt,
        comm: arrivalIInfo?.comm,
        discount: arrivalIInfo?.discount,
        destDisc: arrivalIInfo?.destDisc || 0,
        srcDisc: arrivalIInfo?.srcDisc || 0,
        lbrCharges: arrivalIInfo?.lbrCharges,
        misc: arrivalIInfo?.misc,
        totalPayable: arrivalIInfo?.totalPayable,
        totalReceivable: arrivalIInfo?.totlaReceivable || 0,
        payment: arrivalIInfo?.payment,
        arlDate: arrivalIInfo?.date,
        arlTime: arrivalIInfo?.arlTime,
        inwardPmt: arrivalIInfo?.inwardPmt || 0,
        balPayable: arrivalIInfo?.balPayable || 0,
        invAmt: arrivalIInfo?.invAmt,
        tradeMode: tradeDetails?.tradeMode!,
        freightPaid: tradeDetails?.freightPaid || 0,
        // ? tradeDetails?.freightPaid!
        // : Number(tradeDetails?.freightAmt) -
        //   Number(tradeDetails?.freightAdv),
        weightAtSrc: tradeDetails?.lineItems[0].weight!,
        destWeight: arrivalIInfo?.destWeight!,
        destWastage: arrivalIInfo?.destWastage!,
        destRate: arrivalIInfo?.destRate || 0,
        netWeight:
          (Number(arrivalIInfo?.destWeight) > 0 &&
            Number(arrivalIInfo?.destWeight) -
              Number(arrivalIInfo?.destWastage)) ||
          0,
        arlComments: arrivalIInfo?.arlComments!,
        pattiComments: arrivalIInfo?.pattiComments!,
        commPerVal: arrivalIInfo?.commPerVal,
        finalPayable: arrivalIInfo.finalPayable,
        srcPf: arrivalIInfo?.srcPf,
        paymentMode: arrivalIInfo?.paymentMode!,
        paymentComments: arrivalIInfo?.paymentComments!,
        moistureLoss:
          Number(arrivalIInfo?.weightAtSrc) - Number(arrivalIInfo?.destWeight),
        avgRate: arrivalIInfo?.avgRate,
        pmtHistory: tradeDetails?.pmtHistory || [],
        advancePaid: tradeDetails?.advancePaid || calculateAdvancePaid(),
        finalInvoice: arrivalIInfo?.invAmt,
        rate: arrivalIInfo?.rate || 0,
        paidDate: arrivalIInfo?.paidDate,
        destVehNum: arrivalIInfo?.destVehNum,
        cmdityCost: arrivalIInfo?.cmdityCost,
        finalReceivable: arrivalIInfo?.finalReceivable,
      });
    }
  }, [tradeDetails]);

  useEffect(() => {
    if (alltradeDetail != null) {
      var invAmt =
        Number(arrivalIInfo?.cmdityCost) + Number(arrivalIInfo?.freightAmt);
      var payable =
        // Number(arrivalIInfo?.freightPaid) != 0
        // ?
        invAmt -
        (Number(arrivalIInfo?.freightPaid) +
          calculateAdvancePaid() +
          (Number(arrivalIInfo?.destDisc) || 0));
      //     : Number(arrivalIInfo?.invAmt) -
      //       (Number(arrivalIInfo?.freightAmt) +
      //         calculateAdvancePaid() +
      //         (Number(arrivalIInfo?.destDisc) || 0));
      var receivable =
        // Number(arrivalIInfo?.freightPaid) != 0
        // ?
        invAmt -
        (Number(arrivalIInfo?.freightPaid) +
          alltradeDetail?.advanceRcvd +
          (Number(arrivalIInfo?.srcDisc) || 0));
      setAllTradeDetail({
        ...alltradeDetail,
        freightPaid: arrivalIInfo?.freightPaid || 0,
        // ? arrivalIInfo?.freightPaid
        // : Number(alltradeDetail?.freightAmt) -
        //   Number(alltradeDetail?.freightAdv),
        arlDate: arrivalIInfo.date,
        arlTime: arrivalIInfo.arlTime,
        advancePaid: calculateAdvancePaid(),
        balFreightAmt:
          Number(alltradeDetail?.freightAmt) - Number(arrivalIInfo.freightPaid),
        weightAtSrc: alltradeDetail?.lineItems[0].weight!,
        destWeight: arrivalIInfo?.destWeight!,
        destWastage: arrivalIInfo?.destWastage!,
        netWeight:
          Number(arrivalIInfo.destWastage) > 0 &&
          Number(arrivalIInfo?.destWeight) > 0
            ? parseFloat(
                (
                  Number(arrivalIInfo?.destWeight) -
                  Number(arrivalIInfo.destWastage)
                ).toFixed(2)
              )
            : // : Number(arrivalIInfo?.destWeight) > 0
              // ? Number(arrivalIInfo?.destWeight)
              Number(arrivalIInfo?.netWeight),
        arlComments: arrivalIInfo?.arlComments!,
        moistureLoss:
          Number(alltradeDetail?.weightAtSrc) - Number(arrivalIInfo.destWeight),
        destRate: arrivalIInfo?.destRate,
        cmdityCost: arrivalIInfo?.cmdityCost,
        // alltradeDetail != null && alltradeDetail?.lineItems.length > 0
        //   ? alltradeDetail?.lineItems[0].weightUnit == "RATE_PER_KG"
        //     ? Number(arrivalIInfo?.destRate) * Number(arrivalIInfo?.netWeight)
        //     : Number(arrivalIInfo?.destRate) *
        //       Number(alltradeDetail?.lineItems[0].qty)
        //   : "",
        invAmt:
          Number(arrivalIInfo?.cmdityCost) + Number(arrivalIInfo?.freightAmt),
        rate: arrivalIInfo?.rate,
        totalPayable:
          alltradeDetail?.tradeMode == "ORDER"
            ? payable > 0
              ? payable
              : 0
            : alltradeDetail?.totalPayable,
        totalReceivable:
          alltradeDetail?.tradeMode == "ORDER"
            ? receivable > 0
              ? receivable
              : 0
            : // ? // arrivalIInfo?.freightPaid != 0
              //   //   ?
              //   Number(arrivalIInfo?.invAmt) -
              //   (Number(arrivalIInfo?.freightPaid) +
              //     Number(tradeDetails?.advanceRcvd) +
              //     (Number(arrivalIInfo?.srcDisc) || 0))
              // : Number(arrivalIInfo?.invAmt) -
              //   (Number(arrivalIInfo?.freightAmt) +
              //     calculateAdvancePaidSrc() +
              //     (Number(arrivalIInfo?.srcDisc) || 0))
              alltradeDetail?.totalReceivable,
        destVehNum: arrivalIInfo?.destVehNum,
        finalPayable:
          alltradeDetail?.tradeMode == "ORDER"
            ? payable > 0
              ? Number(payable) +
                Number(alltradeDetail?.destPf) -
                Number(alltradeDetail?.destDis)
              : 0
            : alltradeDetail?.finalPayable,
        finalReceivable:
          alltradeDetail?.tradeMode == "ORDER"
            ? receivable > 0
              ? Number(receivable) -
                Number(alltradeDetail?.srcPf) +
                Number(alltradeDetail?.srcPfDisc)
              : 0
            : // : Number(arrivalIInfo?.invAmt) -
              //   (Number(arrivalIInfo?.freightAmt) + calculateAdvancePaidSrc())
              alltradeDetail?.finalReceivable,
      });
    }
  }, [
    arrivalIInfo?.date,
    arrivalIInfo?.freightPaid,
    arrivalIInfo?.arlTime,
    arrivalIInfo?.destWeight,
    arrivalIInfo?.netWeight,
    arrivalIInfo?.destWastage,
    arrivalIInfo?.arlComments,
    arrivalIInfo?.destRate,
    // arrivalIInfo?.invAmt, d
    arrivalIInfo?.rate,
    arrivalIInfo?.destVehNum,
    arrivalIInfo?.cmdityCost,
  ]);
  // useEffect(() => {
  //   if (alltradeDetail != null && alltradeDetail?.lineItems.length > 0) {
  //     if (alltradeDetail?.lineItems[0].weightUnit == "RAT_PER_KG") {
  //       setArrivalIInfo({
  //         ...arrivalIInfo,
  //         cmdityCost:
  //           Number(arrivalIInfo?.destWastage) > 0
  //             ? Number(arrivalIInfo?.destRate) *
  //               (Number(arrivalIInfo?.destWeight) -
  //                 Number(arrivalIInfo?.destWastage))
  //             : Number(arrivalIInfo?.netWeight) *
  //               Number(arrivalIInfo?.destRate),
  //       });
  //     } else {
  //       setArrivalIInfo({
  //         ...arrivalIInfo,
  //         cmdityCost: parseFloat(
  //           (
  //             Number(arrivalIInfo?.cmdityCost) /
  //             Number(alltradeDetail?.lineItems[0].qty)
  //           ).toFixed(2)
  //         ),
  //       });
  //     }
  //   }
  // }, [
  //   arrivalIInfo?.netWeight,
  //   arrivalIInfo?.destWastage,
  //   arrivalIInfo?.destRate,
  // ]);
  useEffect(() => {
    if (alltradeDetail != null) {
      var payable =
        alltradeDetail?.tradeMode == "ORDER"
          ? Number(arrivalIInfo?.invAmt) -
            (Number(arrivalIInfo?.freightPaid) +
              (Number(arrivalIInfo?.destDisc) || 0) +
              calculateAdvancePaid())
          : Number(arrivalIInfo?.pattiAmt) -
            (Number(arrivalIInfo?.freightPaid) +
              (Number(arrivalIInfo?.comm) || 0) +
              Number(arrivalIInfo?.lbrCharges) +
              (calculateAdvancePaid() || 0) +
              (Number(arrivalIInfo?.misc) || 0));
      var receivable =
        alltradeDetail?.tradeMode == "ORDER"
          ? Number(arrivalIInfo?.invAmt) -
            (Number(arrivalIInfo?.freightPaid) +
              (Number(arrivalIInfo?.srcDisc) || 0) +
              Number(tradeDetails?.advanceRcvd))
          : Number(arrivalIInfo?.pattiAmt) -
            (Number(arrivalIInfo?.freightPaid) +
              Number(arrivalIInfo?.comm) +
              Number(arrivalIInfo?.lbrCharges) +
              Number(tradeDetails?.advanceRcvd) +
              Number(arrivalIInfo?.misc));
      setAllTradeDetail({
        ...alltradeDetail,
        pattiDate: arrivalIInfo.pattiDate,
        pattiAmt: arrivalIInfo.pattiAmt,
        freightPaid: arrivalIInfo.freightPaid,
        comm: arrivalIInfo.comm || 0,
        lbrCharges: arrivalIInfo.lbrCharges,
        discount: arrivalIInfo.discount,
        destDisc: arrivalIInfo?.destDisc || 0,
        srcDisc: arrivalIInfo?.srcDisc || 0,
        misc: arrivalIInfo.misc,
        payment: arrivalIInfo.payment,
        totalPayable: payable > 0 ? payable : alltradeDetail?.totalPayable,
        pattiComments: arrivalIInfo.pattiComments,
        commPerVal: arrivalIInfo.commPerVal,
        finalPayable:
          Number(payable) +
          Number(alltradeDetail?.destPf) -
          Number(alltradeDetail?.destDis),
        avgRate: arrivalIInfo?.avgRate,
        advancePaid: calculateAdvancePaid(),
        totalReceivable:
          receivable > 0 ? receivable : alltradeDetail?.totalReceivable,
        finalReceivable:
          Number(receivable) -
          Number(alltradeDetail?.srcPf) +
          Number(alltradeDetail?.srcPfDisc),
      });
    }
  }, [
    arrivalIInfo.comm,
    arrivalIInfo.pattiAmt,
    arrivalIInfo.lbrCharges,
    arrivalIInfo.misc,
    arrivalIInfo.destDisc,
    arrivalIInfo.payment,
    // arrivalIInfo.freightPaid,
    arrivalIInfo.pattiDate,
    arrivalIInfo.pattiComments,
    arrivalIInfo.commPerVal,
    // arrivalIInfo?.totalPayable,
    arrivalIInfo?.srcPf,
  ]);
  useEffect(() => {
    if (alltradeDetail != null) {
      setAllTradeDetail({
        ...alltradeDetail,
        payment: arrivalIInfo.payment,
        paidDate: arrivalIInfo.paidDate,
        balPayable:
          Number(arrivalIInfo.totalPayable) -
          alltradeDetail?.inwardPmt +
          alltradeDetail?.destPf -
          alltradeDetail?.destDis,
        paymentMode: arrivalIInfo?.paymentMode,
        paymentComments: arrivalIInfo?.paymentComments,
        finalPayable:
          Number(arrivalIInfo?.totalPayable) +
          Number(alltradeDetail?.destPf) -
          Number(alltradeDetail?.destDis),
        // advancePaid: alltradeDetail?.advancePaid,
      });
    }
  }, [
    arrivalIInfo.payment,
    arrivalIInfo.paidDate,
    arrivalIInfo?.paymentMode,
    arrivalIInfo?.paymentComments,
  ]);
  useEffect(() => {
    if (alltradeDetail != null && alltradeDetail?.reqAdv != null) {
      setReqAdvInfo({
        ...reqAdvInfo,
        advLimit: reqAdvInfo.advLimit || 0,
        requestAdv: reqAdvInfo.requestAdv,
        advDate: reqAdvInfo.advDate,
        comments: reqAdvInfo.comments || "",
        // advDisb: tradeDetails?.reqAdv?.advDisb || 0,
        // advApproved: tradeDetails?.reqAdv?.advApproved || 0,
        // disbDate: tradeDetails?.reqAdv?.disbDate || "",
        // status: tradeDetails?.reqAdv?.status || 0,
      });
    }
  }, [
    reqAdvInfo.advDate,
    reqAdvInfo.requestAdv,
    reqAdvInfo?.comments,
    // reqAdvInfo?.paymentComments,
  ]);
  const [calenderOpen, setCalenderOpen] = useState(false);
  const handleDateSelection = (_date: string) => {
    let date = moment(_date).format("YYYY-MM-DD");
    if (alltradeDetail != null) {
      setArrivalIInfo({ ...arrivalIInfo, date: date });
    }
    setCalenderOpen(false);
  };
  const handlePaidDateSelection = (_date: string) => {
    let date = moment(_date).format("YYYY-MM-DD");
    if (alltradeDetail != null) {
      setArrivalIInfo({ ...arrivalIInfo, paidDate: date });
    }
    setCalenderOpen(false);
  };
  const handlePattiDateSelection = (_date: string) => {
    let date = moment(_date).format("YYYY-MM-DD");
    if (alltradeDetail != null) {
      setArrivalIInfo({ ...arrivalIInfo, pattiDate: date });
    }
    setCalenderOpen(false);
  };
  const handleTimeSelection = (time: string) => {
    if (alltradeDetail != null) {
      setArrivalIInfo({ ...arrivalIInfo, arlTime: time });
    }
  };
  const handleRequestDateSelection = (_date: string) => {
    let date = moment().format("YYYY-MM-DD");
    if (alltradeDetail != null) {
      setArrivalIInfo({ ...arrivalIInfo, paidDate: date });
    }
    setCalenderOpen(false);
  };
  const onUploadReceiptImage = async (
    type: "arrival" | "patti" | "payment" | "arrivalWight" | "ADVANCE",
    file: any
  ) => {
    let fileType = "";
    if (file.type === "application/pdf") {
      fileType = "pdf";
    } else if (file.type.includes("image/")) {
      fileType = "image";
    }
    let formdata = new FormData();
    formdata.append("traderId", traderId.toString());
    if (fileType === "pdf") {
      formdata.append("file", file, file.name.replace(" ", "-"));
      let imageUploadResponse = await handlePostCall<string[]>({
        URL: "/mandi/files/trade-portal/imgs/upload",
        apiParams: formdata,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      });
      let url = imageUploadResponse.data[0];
      if (type === "arrival") {
        setOtherDocuments([{ docType: "pdf", url: url }, ...arrivalDocuments]);
      } else if (type === "patti") {
        setPattiDocuments([{ docType: "pdf", url: url }, ...pattiDocuments]);
      } else if (type === "payment") {
        setPaymentDocuments([
          { docType: "pdf", url: url },
          ...paymentDocuments,
        ]);
      } else if (type === "arrivalWight") {
        setArrivalWeightDocuments([
          { docType: "pdf", url: url },
          ...arrivalWeightDocuments,
        ]);
      } else if (type === "ADVANCE") {
        setReqAdvDocuments([{ docType: "pdf", url: url }, ...reqAdvDocuments]);
      }
    } else {
      new Compressor(file, {
        quality: 0.8,
        success: async (compressedResult) => {
          formdata.append(
            "file",
            compressedResult,
            file.name.replace(" ", "-")
          );
          let imageUploadResponse = await handlePostCall<string[]>({
            URL: "/mandi/files/trade-portal/imgs/upload",
            apiParams: formdata,
            config: {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            },
          });
          let url = imageUploadResponse.data[0];
          if (type === "arrival") {
            setOtherDocuments([
              ...arrivalDocuments,
              { docType: "image", url: url },
            ]);
          } else if (type === "patti") {
            setPattiDocuments([
              ...pattiDocuments,
              { docType: "image", url: url },
            ]);
          } else if (type === "payment") {
            setPaymentDocuments([
              ...paymentDocuments,
              { docType: "image", url: url },
            ]);
          } else if (type === "arrivalWight") {
            setArrivalWeightDocuments([
              ...arrivalWeightDocuments,
              { docType: "image", url: url },
            ]);
          } else if (type === "ADVANCE") {
            setReqAdvDocuments([
              ...reqAdvDocuments,
              { docType: "image", url: url },
            ]);
          }
        },
      });
    }
  };
  const onRemoveReceiptImage = (
    type: "arrival" | "patti" | "payment" | "arrivalWight" | "ADVANCE",
    index: number,
    isExist: boolean = false
  ) => {
    if (type == "arrival") {
      if (isExist === true && alltradeDetail !== null) {
        let invoices = [...alltradeDetail.arrivalDocs];
        let i = invoices.findIndex((img) => img.id === index);
        invoices[i] = { ...invoices[i], status: 0 };
        setAllTradeDetail({ ...alltradeDetail, arrivalDocs: invoices });
      } else {
        let newtapalInvoice = [...arrivalDocuments];
        newtapalInvoice.splice(index, 1);
        setOtherDocuments(newtapalInvoice);
      }
    } else if (type == "patti") {
      if (isExist === true && alltradeDetail !== null) {
        let invoices = [...alltradeDetail.pattiDocs];
        let i = invoices.findIndex((img) => img.id === index);
        invoices[i] = { ...invoices[i], status: 0 };
        setAllTradeDetail({ ...alltradeDetail, pattiDocs: invoices });
      } else {
        let newtapalInvoice = [...pattiDocuments];
        newtapalInvoice.splice(index, 1);
        setPattiDocuments(newtapalInvoice);
      }
    } else if (type == "payment") {
      if (isExist === true && alltradeDetail !== null) {
        let invoices = [...alltradeDetail.paymentDocs];
        let i = invoices.findIndex((img) => img.id === index);
        invoices[i] = { ...invoices[i], status: 0 };
        setAllTradeDetail({ ...alltradeDetail, paymentDocs: invoices });
      } else {
        let newtapalInvoice = [...paymentDocuments];
        newtapalInvoice.splice(index, 1);
        setPaymentDocuments(newtapalInvoice);
      }
    } else if (type == "arrivalWight") {
      if (isExist === true && alltradeDetail !== null) {
        let invoices = [...alltradeDetail.arrivalWeightbridgeDocs];
        let i = invoices.findIndex((img) => img.id === index);
        invoices[i] = { ...invoices[i], status: 0 };
        setAllTradeDetail({
          ...alltradeDetail,
          arrivalWeightbridgeDocs: invoices,
        });
      } else {
        let newtapalInvoice = [...arrivalWeightDocuments];
        newtapalInvoice.splice(index, 1);
        setArrivalWeightDocuments(newtapalInvoice);
      }
    } else if (type == "ADVANCE") {
      if (isExist === true && alltradeDetail !== null) {
        let invoices = [...alltradeDetail.reqAdvanceDocuments];
        let i = invoices.findIndex((img) => img.id === index);
        invoices[i] = { ...invoices[i], status: 0 };
        setAllTradeDetail({
          ...alltradeDetail,
          reqAdvanceDocuments: invoices,
        });
      } else {
        let newtapalInvoice = [...reqAdvDocuments];
        newtapalInvoice.splice(index, 1);
        setReqAdvDocuments(newtapalInvoice);
      }
    }
  };

  const confrirmArrival = async () => {
    setBillCreationStatus({ ...billCreationStatus, status: "LOADING" });
    let paidAmmtVal =
      Number(alltradeDetail?.payment) + alltradeDetail?.inwardPmt!;
    let arrrivalDate =
      type == "ARRIVAL"
        ? new Date(alltradeDetail?.arlDate!)
        : type == "PATTI"
        ? new Date(alltradeDetail?.pattiDate!)
        : new Date(alltradeDetail?.paidDate!);
    let arrString =
      type == "ARRIVAL" ? "Arrival" : type == "PATTI" ? "Patti" : "Paid on";
    let tradeDate = moment(alltradeDetail?.date).format("YYYY-MM-DD");
    let date3 = new Date(tradeDate);
    if (arrrivalDate.getTime() < date3.getTime() && type == "ARRIVAL") {
      toast.error(`${arrString}s date can not be prior to the dispatch date.`);
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }
    // var arDate = tradeDetails?.arlDate != "" || tradeDetails?.arlDate != null ? alltradeDetail?.arlDate : ;

    var valueDay = isArrivalDateStatus(alltradeDetail?.arlDate, tradeDate);
    if (!valueDay && (type == "ARRIVAL" || type == "PATTI")) {
      toast.error(
        `${arrString} Date cannot be beyond 7 days from Trade Order created Date.`,
        {
          toastId: "error6",
        }
      );
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }

    // if (
    //   alltradeDetail?.freightPaid == 0 &&
    //   (type == "ARRIVAL" || type == "PATTI")
    // ) {
    //   toast.error("Please enter Freight Paid Amount");
    //   setBillCreationStatus({ ...billCreationStatus, status: "" });
    //   return;
    // }
    if (alltradeDetail?.destWeight == 0 && type == "ARRIVAL") {
      toast.error("Please enter Weight ");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }
    if (
      alltradeDetail?.destWeight! > alltradeDetail?.weightAtSrc! &&
      type == "ARRIVAL"
    ) {
      toast.error("Weight at Destination should be less than Weight at Source");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }
    if (alltradeDetail?.netWeight == 0 && type == "ARRIVAL") {
      toast.error("Please enter Net Weight ");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }
    if (
      alltradeDetail?.destWastage! > alltradeDetail?.destWeight! &&
      type == "ARRIVAL"
    ) {
      toast.error("Wastage should be less than Weight at Destination");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }
    if (
      alltradeDetail?.netWeight! > alltradeDetail?.destWeight! &&
      type == "ARRIVAL"
    ) {
      toast.error("Net Weight should be less than Weight at Destination");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }

    if (
      alltradeDetail?.destRate == 0 &&
      type == "ARRIVAL" &&
      tradeDetails?.tradeMode == "ORDER"
    ) {
      toast.error("Please enter Final Rate ");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }
    if (
      alltradeDetail?.cmdityCost == 0 &&
      type == "ARRIVAL" &&
      tradeDetails?.tradeMode == "ORDER"
    ) {
      toast.error("Please enter Commodity cost ");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }
    if (alltradeDetail?.destVehNum == "" && type == "ARRIVAL") {
      toast.error("Please enter Truck Number ");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }
    if (type == "ARRIVAL" && arrivalDocuments.length === 0) {
      if (alltradeDetail?.arrivalDocs.length === 0) {
        toast.error("Please upload Truck/Freight image.");
        setBillCreationStatus({ ...billCreationStatus, status: "" });
        return;
      }
    }
    if (alltradeDetail?.pattiAmt == 0 && type == "PATTI") {
      toast.error("Please enter Sales Amount");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }
    if (
      (type == "PATTI" || type == "ARRIVAL") &&
      alltradeDetail?.freightPaid != 0 &&
      alltradeDetail?.freightAmt != 0
    ) {
      if (
        Number(alltradeDetail?.freightPaid) > Number(alltradeDetail?.freightAmt)
      ) {
        toast.error("Entered Freight Paid less than the Freight Amount");
        setBillCreationStatus({ ...billCreationStatus, status: "" });
        return;
      }
    }

    if (
      Number(alltradeDetail?.pattiAmt) < Number(alltradeDetail?.freightPaid) &&
      type == "PATTI"
    ) {
      toast.error("Entered Sales Amount not less than the Freight amount");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }
    // if (type == "PATTI" && alltradeDetail?.comm == 0) {
    //   toast.error("Please enter Comission");
    //   setBillCreationStatus({ ...billCreationStatus, status: "" });
    //   return;
    // }
    if (
      type == "PATTI" &&
      Number(alltradeDetail?.pattiAmt) < Number(alltradeDetail?.advancePaid)
    ) {
      toast.error("Entered Sales Amount not less than the Advance Paid");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }
    if (arrrivalDate.getTime() < date3.getTime() && type == "PATTI") {
      toast.error(`${arrString} date can not be prior to the dispatch date.`);
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }
    if (type == "PATTI" && pattiDocuments.length === 0) {
      if (alltradeDetail?.pattiDocs.length === 0) {
        toast.error(
          "Please upload patti image/pdf or take a picture of patti."
        );
        setBillCreationStatus({ ...billCreationStatus, status: "" });
        return;
      }
    }
    // if (type == "PATTI" && alltradeDetail?.lbrCharges == 0) {
    //   toast.error("Please enter Comission");
    //   return;
    // }
    var valueDayPayment = isPaymentDateBeforeArrivalStatus(
      alltradeDetail?.arlDate,
      tradeDate,
      alltradeDetail?.paidDate
    );
    if (
      !valueDayPayment &&
      type == "PAYMENT" &&
      (tradeDetails?.arlTime == null || tradeDetails?.arlTime == "")
    ) {
      toast.error(`${arrString} date can not be prior to the dispatch date`, {
        toastId: "error6",
      });
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }
    if (arrrivalDate.getTime() < date3.getTime() && type == "PAYMENT") {
      toast.error(`${arrString} date can not be prior to the dispatch date.`);
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }
    if (type == "PAYMENT" && alltradeDetail?.payment == 0) {
      toast.error("Please enter Amount");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }
    if (type == "PAYMENT" && paymentDocuments.length === 0) {
      if (alltradeDetail?.paymentDocs.length === 0) {
        toast.error("Please upload atleast one payment receipt");
        setBillCreationStatus({ ...billCreationStatus, status: "" });
        return;
      }
    }
    // if (
    //   type == "PAYMENT" &&
    //   alltradeDetail?.totalPayable != 0 &&
    //   alltradeDetail?.payment
    // ) {
    //   if (alltradeDetail?.payment > alltradeDetail?.finalPayable) {
    //     toast.error("Entered Amount less than Final Paybles");
    //     setBillCreationStatus({ ...billCreationStatus, status: "" });
    //     return;
    //   }
    // }
    // if (
    //   type == "PAYMENT" &&
    //   alltradeDetail?.totalPayable != 0 &&
    //   alltradeDetail?.balPayable != 0 &&
    //   alltradeDetail?.payment
    // ) {
    //   if (alltradeDetail?.payment > alltradeDetail?.balPayable) {
    //     toast.error("Please enter the amount less or equal to balance payable");
    //     setBillCreationStatus({ ...billCreationStatus, status: "" });
    //     return;
    //   }
    // }
    if (type == "PAYMENT" && alltradeDetail?.paymentMode == "") {
      toast.error("Please Select payment mode");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }
    try {
      let imgs: Array<{
        id: number;
        imgFrom: string;
        reqId: number;
        status: number;
        addedBy: number;
        type:
          | "tapal"
          | "receipt"
          | "other"
          | "arrival"
          | "patti"
          | "payment"
          | "arrivalWight";
        url: string;
      }> = [];
      let arrivalImages = imgs;
      let pattiImages = imgs;
      let paymentImages = imgs;
      let arrivalWeightImages = imgs;
      if (arrivalDocuments.length > 0) {
        for (let file of arrivalDocuments) {
          arrivalImages.push({
            id: 0,
            imgFrom: "DEST_TRADER",
            reqId: 0,
            status: 1,
            addedBy: traderId || 0,
            type: "arrival",
            url: file.url,
          });
        }
      } else {
        if (alltradeDetail != null) {
          if (alltradeDetail?.arrivalDocs.length > 0) {
            for (let file of alltradeDetail?.arrivalDocs) {
              if (file.status == 0) {
                arrivalImages.push({
                  ...file,
                  addedBy: traderId,
                });
              }
            }
          }
        }
      }
      if (pattiDocuments.length > 0) {
        for (let file of pattiDocuments) {
          pattiImages.push({
            id: 0,
            imgFrom: "DEST_TRADER",
            reqId: 0,
            status: 1,
            addedBy: traderId || 0,
            type: "patti",
            url: file.url,
          });
        }
      } else {
        if (alltradeDetail != null) {
          if (alltradeDetail?.pattiDocs.length > 0) {
            for (let file of alltradeDetail?.pattiDocs) {
              if (file.status == 0) {
                pattiImages.push({
                  ...file,
                  addedBy: traderId,
                });
              }
            }
          }
        }
      }
      if (paymentDocuments.length > 0) {
        for (let file of paymentDocuments) {
          paymentImages.push({
            id: 0,
            imgFrom: "DEST_TRADER",
            reqId: 0,
            status: 1,
            addedBy: traderId || 0,
            type: "payment",
            url: file.url,
          });
        }
      } else {
        if (alltradeDetail != null) {
          if (alltradeDetail?.paymentDocs.length > 0) {
            for (let file of alltradeDetail?.paymentDocs) {
              if (file.status == 0) {
                paymentImages.push({
                  ...file,
                  addedBy: traderId,
                });
              }
            }
          }
        }
      }
      if (type == "ARRIVAL") {
        if (arrivalWeightDocuments.length > 0) {
          for (let file of arrivalWeightDocuments) {
            arrivalWeightImages.push({
              id: 0,
              imgFrom: "DEST_TRADER",
              reqId: 0,
              status: 1,
              addedBy: traderId || 0,
              type: "arrivalWight",
              url: file.url,
            });
          }
        } else {
          if (alltradeDetail != null && editStatus) {
            if (alltradeDetail?.arrivalWeightbridgeDocs.length > 0) {
              for (let file of alltradeDetail?.arrivalWeightbridgeDocs) {
                if (file.status == 0) {
                  arrivalImages.push({
                    ...file,
                    addedBy: traderId,
                  });
                }
              }
            }
          }
        }
      }
      let postBody = {};

      if (type == "ARRIVAL") {
        postBody = {
          arlConf: {
            arlDate: alltradeDetail?.arlDate,
            freightPaid: alltradeDetail?.freightPaid,
            images: arrivalImages,
            arlTime: alltradeDetail?.arlTime,
            comments: alltradeDetail?.arlComments,
            addWastage: alltradeDetail?.destWastage,
            destWeight: alltradeDetail?.destWeight,
            // arlTime:18:1:50
            tradeId: alltradeDetail?.tradeId,
            destTradeStatus: editStatus
              ? alltradeDetail?.tradeStatus
              : "ARL_CONFIRMED",
            destRate: alltradeDetail?.destRate,
            invAmt:
              alltradeDetail?.tradeMode == "ORDER" ? alltradeDetail?.invAmt : 0,
            totalPayable: alltradeDetail?.totalPayable,
            totalReceivable: alltradeDetail?.totalReceivable,
            destVehNum: alltradeDetail?.destVehNum,
            cmdityCost:
              alltradeDetail?.tradeMode == "ORDER"
                ? alltradeDetail?.cmdityCost
                : "",
            finalPayable: alltradeDetail?.finalPayable,
            finalReceivable: alltradeDetail?.finalReceivable,
          },
          traderId: traderId,
          tradeId: alltradeDetail?.tradeId,
          tradeMode: alltradeDetail?.tradeMode,
        };
      } else if (type == "PATTI") {
        postBody = {
          pattiFinal: {
            comm: alltradeDetail?.comm,
            discount: alltradeDetail?.discount,
            freightPaid: alltradeDetail?.freightPaid,
            images: pattiImages,
            lbrCharges: alltradeDetail?.lbrCharges,
            misc: alltradeDetail?.misc,
            pattiAmt: alltradeDetail?.pattiAmt,
            totalPayable: alltradeDetail?.totalPayable,
            tradeId: alltradeDetail?.tradeId,
            destTradeStatus: editStatus
              ? alltradeDetail?.tradeStatus
              : "PATTI_FINALIZED",
            pattiDate: alltradeDetail?.pattiDate,
            comments: alltradeDetail?.pattiComments,
            totalReceivable: alltradeDetail?.totalReceivable,
            finalPayable: alltradeDetail?.finalPayable,
            finalReceivable: alltradeDetail?.finalReceivable,
          },
          traderId: traderId,
          tradeId: alltradeDetail?.tradeId,
          tradeMode: alltradeDetail?.tradeMode,
        };
      } else if (type == "INVOICE") {
        postBody = {
          pattiFinal: {
            comm: alltradeDetail?.comm,
            discount: alltradeDetail?.destDisc,
            freightPaid: alltradeDetail?.freightPaid,
            images: pattiImages,
            lbrCharges: alltradeDetail?.lbrCharges,
            misc: alltradeDetail?.misc,
            pattiAmt: alltradeDetail?.pattiAmt,
            totalPayable: alltradeDetail?.totalPayable,
            tradeId: alltradeDetail?.tradeId,
            destTradeStatus:
              // editStatus
              alltradeDetail?.tradeStatus,
            // : "PATTI_FINALIZED",
            pattiDate: alltradeDetail?.pattiDate,
            comments: alltradeDetail?.pattiComments,
            totalReceivable: alltradeDetail?.totalReceivable,
            finalPayable: alltradeDetail?.finalPayable,
            finalReceivable: alltradeDetail?.finalReceivable,
          },
          traderId: traderId,
          tradeId: alltradeDetail?.tradeId,
          tradeMode: alltradeDetail?.tradeMode,
          invAmt: alltradeDetail?.invAmt,
        };
      } else if (type == "PAYMENT") {
        dispatch(
          updateTradeDetails({
            tradeDetails: {
              ...alltradeDetail!,
              totalPayable: alltradeDetail?.totalPayable!,
              balPayable:
                Number(alltradeDetail?.totalPayable) -
                Number(alltradeDetail?.inwardPmt) +
                alltradeDetail?.destPf! -
                alltradeDetail?.destDis!,
            },
          })
        );
        postBody = {
          pmtDtls: {
            // account: "",
            // ifsc: "HDFC0001012",
            images: paymentImages,
            // payableTo: "ONO ARK INDIA PVT. LTD",
            payment: alltradeDetail?.payment,
            totalPayable: alltradeDetail?.totalPayable,
            totalReceivable: alltradeDetail?.totalReceivable,
            tradeId: alltradeDetail?.tradeId,
            paidAmt: paidAmmtVal,
            destTradeStatus:
              alltradeDetail?.inwardPmt! > 0
                ? alltradeDetail?.finalPayable == paidAmmtVal
                  ? "PMT_COMPLETED"
                  : alltradeDetail?.tradeStatus
                : alltradeDetail?.finalPayable == paidAmmtVal
                ? "PMT_COMPLETED"
                : "PMT_PENDING",
            comments: alltradeDetail?.paymentComments,
            date: alltradeDetail?.paidDate,
            paymentMode: alltradeDetail?.paymentMode,
            tradeType: alltradeDetail?.tradeMode,
            paymentType:
              (alltradeDetail?.pattiAmt == 0 &&
                alltradeDetail?.tradeMode == "COMM") ||
              (alltradeDetail?.tradeMode == "ORDER" &&
                alltradeDetail?.lineItems[0].destWeight == 0)
                ? "ADVANCE"
                : "",
          },
          tradeId: alltradeDetail?.tradeId,
          traderId: traderId,
          tradeMode: alltradeDetail?.tradeMode,
        };
      }

      await handlePutCall<any>({
        URL: "mandi/trade-portal/trades",
        apiParams: postBody,
      })
        .then(({ data, status }) => {
          toast.success(status.message);
          // navigate(`/orders`);
          navigate(`/order-view/${alltradeDetail?.tradeId}`);
          window.location.reload();
          dispatch(
            updateTradeId({ tradeId: alltradeDetail?.tradeId.toString()! })
          );
        })
        .catch((err: any) => {
          setBillCreationStatus({ ...billCreationStatus, status: "" });
          toast.error(err.response.data.status.message);
          setBillCreationStatus({ ...billCreationStatus, status: "" });
        });
    } catch (ex) {
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      // toast.update(toastid, {
      //   type: "error",
      //   render: "File upload failed please try again",
      //   isLoading: false,
      //   autoClose: 2000,
      // });
    }
  };
  const updatePayment = async () => {
    setBillCreationStatus({ ...billCreationStatus, status: "LOADING" });
    let paidAmmtVal =
      Number(alltradeDetail?.payment) + alltradeDetail?.inwardPmt!;
    setBillCreationStatus({ ...billCreationStatus, status: "LOADING" });
    try {
      let imgs: Array<{
        id: number;
        imgFrom: string;
        reqId: number;
        status: number;
        addedBy: number;
        type:
          | "tapal"
          | "receipt"
          | "other"
          | "arrival"
          | "patti"
          | "payment"
          | "arrivalWight";
        url: string;
        typeId: number;
      }> = [];
      let paymentImages = imgs;
      if (paymentDocuments.length > 0) {
        for (let file of paymentDocuments) {
          paymentImages.push({
            id: 0,
            imgFrom: "DEST_TRADER",
            reqId: 0,
            status: 1,
            addedBy: traderId || 0,
            type: "payment",
            url: file.url,
            typeId: 40,
          });
        }
      } else {
        if (alltradeDetail != null) {
          if (alltradeDetail?.paymentDocs.length > 0) {
            for (let file of alltradeDetail?.paymentDocs) {
              if (file.status == 0) {
                paymentImages.push({
                  ...file,
                  addedBy: traderId,
                  typeId: 40,
                });
              }
            }
          }
        }
      }
      let tradeDate = moment(alltradeDetail?.date).format("YYYY-MM-DD");
      let date3 = new Date(tradeDate);
      if (new Date(alltradeDetail?.paidDate!).getTime() < date3.getTime()) {
        toast.error(`Paid on date can not be prior to the dispatch date.`);
        setBillCreationStatus({ ...billCreationStatus, status: "" });
        return;
      }
      let postBody = {
        action: "UPDATE",
        amount: alltradeDetail?.payment,
        id: paymentHistoryObj?.id,
        images: paymentImages,
        pmtMode: alltradeDetail?.paymentMode,
        status: 2,
        tradeId: alltradeDetail?.tradeId,
        comments: alltradeDetail?.paymentComments,
        pmtType: paymentHistoryObj?.pmtType.toUpperCase(),
        date: alltradeDetail?.paidDate,
        traderId: traderId,
        // totalPayable:
        //   Number(alltradeDetail?.totalPayable) +
        //   (Number(alltradeDetail?.payment) > Number(paymentHistoryObj?.amount ? (Number(alltradeDetail?.payment) - Number(paymentHistoryObj?.amount)) ),
      };

      await handlePutCall<any>({
        URL: "mandi/trade-portal/trades/payments",
        apiParams: postBody,
      })
        .then(({ data, status }) => {
          toast.success(status.message);
          console.log(data, "data");
          // navigate(`/orders`);
          navigate(`/order-view/${alltradeDetail?.tradeId}`);
          window.location.reload();
          dispatch(
            updateTradeId({ tradeId: alltradeDetail?.tradeId.toString()! })
          );
        })
        .catch((err: any) => {
          setBillCreationStatus({ ...billCreationStatus, status: "" });
          toast.error(err.response.data.status.message);
          setBillCreationStatus({ ...billCreationStatus, status: "" });
        });
    } catch (ex) {
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      // toast.update(toastid, {
      //   type: "error",
      //   render: "File upload failed please try again",
      //   isLoading: false,
      //   autoClose: 2000,
      // });
    }
  };
  const requestForAdvance = async (edit: boolean) => {
    setBillCreationStatus({ ...billCreationStatus, status: "LOADING" });
    if (!edit) {
      setOtpExpTime(60);
      if (otpTimer !== null) {
        clearTimeout(otpTimer);
      }
      otpTimer = setTimeout(() => {
        runOtpTimer(59);
      }, 1000);
    }
    try {
      let imgs: Array<{
        id: number;
        imgFrom: string;
        reqId: number;
        status: number;
        addedBy: number;
        type:
          | "tapal"
          | "receipt"
          | "other"
          | "arrival"
          | "patti"
          | "payment"
          | "arrivalWight"
          | "ADVANCE";
        url: string;
        typeId: number;
      }> = [];
      let images = imgs;
      if (reqAdvDocuments.length > 0) {
        for (let file of reqAdvDocuments) {
          images.push({
            id: 0,
            imgFrom: "DEST_TRADER",
            reqId: 0,
            status: 1,
            addedBy: traderId || 0,
            type: "ADVANCE",
            url: file.url,
            typeId: 0,
          });
        }
      } else {
        if (alltradeDetail != null) {
          if (alltradeDetail?.reqAdvanceDocuments.length > 0) {
            for (let file of alltradeDetail?.reqAdvanceDocuments) {
              if (file.status == 0) {
                images.push({
                  ...file,
                  addedBy: traderId,
                  typeId: file.id,
                });
              }
            }
          }
        }
      }
      // let tradeDate = moment(alltradeDetail?.date).format("YYYY-MM-DD");
      // let date3 = new Date(tradeDate);
      if (reqAdvInfo?.requestAdv == 0) {
        toast.error(`Please enter Advance`);
        setBillCreationStatus({ ...billCreationStatus, status: "" });
        return;
      }
      if (
        reqAdvInfo?.requestAdv != 0 &&
        Number(reqAdvInfo?.requestAdv) > alltradeDetail?.advLimit!
      ) {
        toast.error(`Entered Advance should be less than advance limit`);
        setBillCreationStatus({ ...billCreationStatus, status: "" });
        return;
      }
      if (reqAdvDocuments.length === 0) {
        if (alltradeDetail?.reqAdvanceDocuments.length === 0) {
          toast.error("Please upload atleast one image");
          setBillCreationStatus({ ...billCreationStatus, status: "" });
          return;
        }
      }
      if (!isChecked) {
        toast.error(`Please accept terms and conditions`);
        setBillCreationStatus({ ...billCreationStatus, status: "" });
        return;
      }

      let postBody = {
        advApproved: 0,
        advDate: reqAdvInfo?.advDate,
        advDisb: reqAdvInfo?.advDisb,
        advLimit: reqAdvInfo?.advLimit,
        comments: reqAdvInfo?.comments,
        disbDate: reqAdvInfo?.disbDate,
        id: 0,
        images: images,
        mobile: alltradeDetail?.srcMobile,
        requestAdv: reqAdvInfo?.requestAdv,
        srcName: alltradeDetail?.srcName,
        srcPartyId: alltradeDetail?.srcPartyId,
        status: reqAdvInfo?.status,
        tradeId: alltradeDetail?.tradeId,
        tnc: isChecked ? 1 : 0,
      };

      await handlePostCall<any>({
        URL: "mandi/trade-portal/trades/source/advance",
        apiParams: postBody,
      })
        .then(({ data, status }) => {
          toast.success(status.message);
          console.log(data, "data");
          // navigate(`/orders`);
          if (!edit) {
            setOtpVerificationModal(true);
          } else {
            closeForm(false);
            setBillCreationStatus({
              ...billCreationStatus,
              status: "",
            });
            navigate(`/order-view/${alltradeDetail?.tradeId}`);
            window.location.reload();
          }
          // navigate(`/order-view/${alltradeDetail?.tradeId}`);
          // window.location.reload();
          dispatch(
            updateTradeId({ tradeId: alltradeDetail?.tradeId.toString()! })
          );
        })
        .catch((err: any) => {
          setBillCreationStatus({ ...billCreationStatus, status: "" });
          toast.error(err.response.data.status.message);
          setBillCreationStatus({ ...billCreationStatus, status: "" });
        });
    } catch (ex) {
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      // toast.update(toastid, {
      //   type: "error",
      //   render: "File upload failed please try again",
      //   isLoading: false,
      //   autoClose: 2000,
      // });
    }
  };
  const requestAdvanceOTP = async () => {
    try {
      if (reqAdvInfo?.srcOtp == "") {
        toast.error(`Please enter OTP`);
        setBillCreationStatus({ ...billCreationStatus, status: "" });
        return;
      }
      // }
      // if (
      //   reqAdvInfo?.requestAdv != 0 &&
      //   Number(reqAdvInfo?.requestAdv) > reqAdvInfo?.advLimit
      // ) {
      //   toast.error(`Entered Advance should be less than advance limit`);
      //   setBillCreationStatus({ ...billCreationStatus, status: "" });
      //   return;
      // }
      // if (!isChecked) {
      //   toast.error(`Please accept terms and conditons`);
      //   setBillCreationStatus({ ...billCreationStatus, status: "" });
      //   return;
      // }
      let postBody = {
        srcEsign: "",
        srcOtp: reqAdvInfo?.srcOtp,
        srcPartyId: alltradeDetail?.srcPartyId,
        tradeId: alltradeDetail?.tradeId,
      };
      // ?srcOtp=${reqAdvInfo?.srcOtp}&srcPartyId=${alltradeDetail?.srcPartyId}&tradeId=${alltradeDetail?.tradeId}
      await handlePostCall<any>({
        URL: `mandi/trade-portal/trades/source/adv/otp-val`,
        apiParams: postBody,
      })
        .then(({ data, status }) => {
          toast.success(status.message);
          // navigate(`/orders`);
          // setOtpVerificationModal(true);
          navigate(`/order-view/${alltradeDetail?.tradeId}`);
          window.location.reload();
          dispatch(
            updateTradeId({ tradeId: alltradeDetail?.tradeId.toString()! })
          );
        })
        .catch((err: any) => {
          toast.error(err.response.data.status.message);
        });
    } catch (ex) {
      // toast.update(toastid, {
      //   type: "error",
      //   render: "File upload failed please try again",
      //   isLoading: false,
      //   autoClose: 2000,
      // });
    }
  };
  const requestAdvance = () => {};
  return {
    alltradeDetail,
    confrirmArrival,
    onUploadReceiptImage,
    arrivalDocuments,
    onRemoveReceiptImage,
    handleDateSelection,
    calenderOpen,
    setCalenderOpen,
    arlDate,
    setArrivalIInfo,
    arrivalIInfo,
    pattiDocuments,
    paymentDocuments,
    handlePaidDateSelection,
    // timeChange,
    // value,
    handleTimeSelection,
    arrivalWeightDocuments,
    handlePattiDateSelection,
    updatePayment,
    paymentHistoryObj,
    billCreationStatus,
    checkHandler,
    isChecked,
    setIsChecked,
    termsAndConditionsModal,
    setTermsAndConditionsModal,
    otpVerificationModal,
    setOtpVerificationModal,
    handleRequestDateSelection,
    otpExpTime,
    sentOtpBtnText,
    setOtpExpTime,
    requestAdvance,
    reqAdvInfo,
    setReqAdvInfo,
    requestForAdvance,
    requestAdvanceOTP,
    reqAdvDocuments,
  };
};
export default useArrivalsView;
