import { useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
const NotFound = (props: any) => {
  const { param } = useParams(); // to capture path parameters
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (param?.includes("tid-")) {
      let tid = param.replace("tid-", "");
      navigate(`/order-view/${tid}?onBack=home`);
    }
    const searchParams = new URLSearchParams(location.search);
    const queryId = searchParams.get("tid");
    console.log(queryId, "queryId");
    if (queryId) {
      navigate(`/order-view/${queryId}?onBack=home`);
    }
  }, [param, location, navigate]);
  return <></>;
};
export default NotFound;
