import React, { FC } from "react";
import { Style } from "util";
const DocumentViewer: FC<{ docType: "image" | "pdf"; url: string; style?: React.CSSProperties; }> = ({
  docType,
  url,
  style
}) => {
  return (
    <>
      {docType === "image" ? (
        <img src={url} />
      ) : docType === "pdf" ? (
        <iframe src={url} className="h-full w-full" style={{pointerEvents: "none"}}></iframe>
      ) : (
        <></>
      )}
    </>
  );
};
export default DocumentViewer;
