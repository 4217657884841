import { FC, useContext, useEffect } from "react";
import { FaExpand } from "react-icons/fa6";
import PageHeader from "../../../components/commons/page-header";
import PageMainHeader from "../../../components/commons/page-main-header";
import useArrivalsView from "./useArrivalsView";
import useOrderView from "./useOrderView";
import DocumentPreViewer from "../components/document-viewer";
import { deviceInfoContext } from "../../../context";
import edit from "../../../assets/images/edit_round.svg";
import cancel from "../../../assets/images/cancel.svg";
import prev from "../../../assets/images/prev.svg";
import next from "../../../assets/images/next.svg";
import { useState } from "react";
import Modal from "../../../components/ui/modal";
import ModalHeader from "../../../components/ui/modal/modal-header";
import { Button } from "../../../components/ui";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "../../../store";
import { useNavigate, useParams } from "react-router-dom";
import { updatePaymentUpdateStatus } from "../../../reducers/tradeSlice";
import { toast } from "react-toastify";
import useAxiosClient from "../../../hooks/useAxiosClient";
const PaymentHistory: FC<{}> = ({}) => {
  const { width, deviceType } = useContext(deviceInfoContext);
  const params = useParams();
  const { onClickPaymentId } = useOrderView();
  const { alltradeDetail } = useArrivalsView("PAYMENT", false);
  const { handlePutCall } = useAxiosClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [index, setActiveStep] = useState(0);
  const [showCancelPayModal, setShowCancelPayModal] = useState(false);
  // Function to go to the next picture

  useEffect(() => {
    onClickPaymentId();
  }, []);
  const goToNextPicture = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const goToPrevPicture = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const { tradeType, paymentHistoryObj, traderId } = useSelector(
    (state: TRootState) => ({
      tradeType: state.auth.tradeType,
      traderId: state.auth.traderId,
      paymentHistoryObj: state.trades.paymentHistoryObj,
    })
  );
  const updatePayment = async () => {
    try {
      let postBody = {
        action: "CANCEL",
        amount: alltradeDetail?.payment,
        id: paymentHistoryObj?.id,
        images: [],
        pmtMode: "CASH",
        status: 0,
        tradeId: alltradeDetail?.tradeId,
        pmtType: paymentHistoryObj?.pmtType.toUpperCase(),
        traderId: traderId,
      };

      await handlePutCall<any>({
        URL: "mandi/trade-portal/trades/payments",
        apiParams: postBody,
      })
        .then(({ data, status }) => {
          toast.success(status.message);
          // navigate(`/orders`);
          navigate(`/order-view/${alltradeDetail?.tradeId}`);
          window.location.reload();
        })
        .catch((err: any) => {
          toast.error(err.response.data.status.message);
        });
    } catch (ex) {}
  };
  return (
    <>
      <PageMainHeader />
      <PageHeader
        showBackButton={true}
        showTitle={true}
        fromScreen={`PaymentHistory`}
        topVal="3.7rem"
        title={` ${
          paymentHistoryObj != null
            ? paymentHistoryObj?.pmtType + ": " + paymentHistoryObj?.id
            : ""
        }`}
        subText={alltradeDetail?.tradeStatus}
      />
      {paymentHistoryObj != null && (
        <>
          {paymentHistoryObj?.images != null &&
            paymentHistoryObj?.images.length > 0 && (
              <>
                <div className="flex flex-row overflow-auto hide-scroll-bar">
                  {paymentHistoryObj.images.map((item, i) => (
                    <div
                      key={`receipt-document-${i}`}
                      className="p-4 shrink-0 w-full"
                    >
                      <div className="border-2 rounded overflow-hidden cursor-pointer relative">
                        {/* {paymentHistoryObj.images[index].url.includes(".pdf") ===
                        "image" && (
                        <img
                          src={paymentHistoryObj.images[index].url}
                          className=""
                        />
                      )} */}
                        <div>
                          {paymentHistoryObj.images[index].url.includes(
                            ".pdf"
                          ) ? (
                            <>
                              <iframe
                                src={paymentHistoryObj.images[index].url}
                                className="h-full w-full"
                                style={{
                                  height:
                                    deviceType == "MOBILE" ? "60vh" : "65vh",
                                }}
                              ></iframe>
                            </>
                          ) : (
                            <img
                              src={paymentHistoryObj.images[index].url}
                              className=""
                            />
                          )}
                        </div>
                      </div>
                      <div className="flex mt-4 justify-center items-center gap-3 w-full">
                        <button
                          disabled={index === 0}
                          onClick={() => {
                            goToPrevPicture();
                          }}
                        >
                          <img src={prev} className="w-6" alt="image" />
                        </button>
                        <button
                          className="nextPrev"
                          disabled={
                            index === paymentHistoryObj?.images.length - 1
                          }
                          onClick={() => {
                            goToNextPicture();
                          }}
                        >
                          <img src={next} className="w-6" alt="image" />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          {/* {tradeType == "DEST" && (
            <div
              className="bg-white flex justify-between p-4 fixed bottom-0 w-full border-t"
              style={{ width: width }}
              data-element="order-view-footer"
            >
              <span
                className="flex flex-col items-center cursor-pointer"
                onClick={() => {
                  setShowCancelPayModal(true);
                }}
              >
                <img src={cancel} alt="image" className="w-8 h-8" />
                <span className="text-xs">Cancel</span>
              </span>
              <span
                className="flex flex-col items-center cursor-pointer"
                onClick={() => {
                  navigate(`/order-view/${paymentHistoryObj.tradeId}`);
                  localStorage.setItem("activeTab", "paymentDetails");
                  dispatch(
                    updatePaymentUpdateStatus({ paymentUpdateStatus: true })
                  );
                }}
              >
                <img src={edit} alt="image" className="w-8 h-8" />
                <span className="text-xs">Edit</span>
              </span>
            </div>
          )} */}

          {showCancelPayModal === true && (
            <>
              <Modal
                open={showCancelPayModal}
                width={deviceType === "MOBILE" ? "80vw" : "400px"}
                onClose={() => {
                  setShowCancelPayModal(false);
                }}
                modalWidth={deviceType === "MOBILE" ? "100vw" : width}
              >
                <>
                  <ModalHeader title={"Cancel Payment"} content={<></>} />
                  <div className="text-center py-3 px-6">
                    <img
                      src={cancel}
                      className="h-8 w-8 flex justify-center items-center mx-auto mb-2"
                    />
                    <span className="text-sm">
                      You are about to cancel the payment. Are you sure you want
                      to proceed?
                    </span>
                  </div>
                  <div className="flex w-full items-center bottom-0 left-0 right-0 px-4 py-4 border-top gap-2">
                    <Button
                      variant="primaryLight"
                      className="w-full"
                      text="NO"
                      onClick={() => {
                        setShowCancelPayModal(false);
                      }}
                    />
                    <Button
                      variant="contained"
                      className="w-full"
                      onClick={() => {
                        updatePayment();
                      }}
                    >
                      YES
                    </Button>
                  </div>
                </>
              </Modal>
            </>
          )}
        </>
      )}
    </>
  );
};
export default PaymentHistory;
function openInFullScreen(url: string, arg1: string, docType: string) {
  throw new Error("Function not implemented.");
}
