import { FC, ChangeEvent, FocusEvent, CSSProperties } from "react";
import classes from "./style.module.scss";

const Input: FC<{
  label?: string;
  palceholder?: string;
  type?: string;
  required?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  value?: string | number;
  style?: CSSProperties;
  allowNegativeNumber?: boolean;
  disabled?: boolean;
  maxLength?: number;
}> = ({
  label,
  palceholder,
  type,
  required,
  onChange,
  onFocus,
  onBlur,
  allowNegativeNumber,
  style,
  value,
  maxLength,
  disabled = false,
}) => {
  return (
    <>
      <div className={classes.inputContainer}>
        {label !== "" && (
          <label className="text-sm font-semibold">
            {label}{" "}
            <span className="color-red">{required === true && "*"}</span>
          </label>
        )}
        <input
          type={type || "text"}
          style={style}
          className="w-full border px-3 py-2 font-medium"
          placeholder={palceholder || ""}
          onWheel={(e) => (e.target as HTMLElement).blur()}
          onChange={(e) => {
            if (!onChange) {
              return;
            }
            if (type === "number") {
              let regexp = /^[0-9]*(\.[0-9]{0,2})?$/;
              if (allowNegativeNumber === true) {
                regexp = /^[-0-9]*(\.[0-9]{0,2})?$/;
              }
              if (regexp.test(e.target.value) == true) {
                onChange(e);
              } else {
                e.target.value = parseFloat(e.target.value).toFixed(2);
                onChange(e);
              }
            } else {
              onChange(e);
            }
          }}
          onFocus={onFocus}
          value={value}
          disabled={disabled}
        />
      </div>
    </>
  );
};
export default Input;
