import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

// import "../../../fonts"; // Ensure the fonts are registered
import watermark from "../../assets/images/watermark.png"; // Make sure this path is correct
import ono_logo from "../../assets/images/ono_logo_pdf.png";
import tradeStatusText from "../../helpers/tradeStatusText";
import { getMaskedMobileNumber } from "../../helpers/get-currency-number";
import defultProfilePic from "../../assets/images/single_bill.svg";
import moment from "moment";
const styles = StyleSheet.create({
  page: {
    paddingTop: 90, // Adjust to leave space for the header
    paddingBottom: 100, // Adjust to leave space for the footer
    paddingHorizontal: 40,
    fontFamily: "Roboto",
  },
  header: {
    fontSize: 11,
    color: "#000000",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: "10px solid #16A02C",
    paddingBottom: 10,
    paddingLeft: 40,
    paddingRight: 40,
    // marginBottom: 70,
    paddingTop: 20,
    borderBottom: "1px solid black",
  },
  logo: {
    width: 100,
  },
  header_head_main: {
    fontSize: 14,
    textAlign: "center",
    // marginLeft: "15%",
    // marginRight: "auto",
    // fontWeight: "bold",
  },
  header_head: {
    fontSize: 14,
    textAlign: "center",
  },
  footer: {
    fontSize: 12,
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "left",
    paddingLeft: 40,
    paddingRight: 40,
    borderTop: "1px solid black",
    paddingTop: 20,
    // color: "grey",
  },
  watermark: {
    position: "absolute",
    top: "30%",
    left: "15%",
    width: 300,
    height: 300,
    // opacity: 0.3, // Make the watermark semi-transparent
    // transform: "rotate(-45deg)",
    zIndex: -1, // Ensure it's behind the content
  },
  section: {
    marginBottom: 20,
    fontSize: 11,
    paddingTop: 20,
    lineHeight: 1.5,
  },
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    borderCollapse: "collapse",
    // borderBottom: "0px", // This should help to collapse the borders
    borderLeft: "0px",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1, // Apply border only to the bottom
    borderColor: "#000",
  },
  tableCol: {
    width: "20%",
    padding: 5,
    borderLeftWidth: 1, // Apply border only to the left (except for first column)
    borderColor: "#000",
    // borderBottomWidth: 1, // Apply border to bottom to create a complete row
  },
  tableCell: {
    fontSize: 10,
  },
  para: {
    lineHeight: 1.5,
  },
  subHeading: {
    fontSize: 12,
    fontWeight: "bold",
    marginVertical: 5,
  },
  paragraph: {
    marginBottom: 10,
  },
  list: {
    marginLeft: 10,
    marginBottom: 10,
  },
  listItem: {
    marginBottom: 5,
    marginLeft: 10,
    display: "flex",
    flexDirection: "row",
  },
  listItemLeft: {
    justifyContent: "flex-end",
    textAlign: "right",
  },
  footerPara: {
    fontSize: 11,
    color: "#121212",
  },
  footerParaSm: {
    fontSize: 10,
    color: "#000000",
  },
  bold: {
    fontWeight: "bold",
  },
  space: {
    paddingBottom: 10,
  },
  pageno: {
    paddingTop: 10,
    textAlign: "center",
  },

  mainpara: {
    paddingBottom: 10,
  },
  relative: {
    position: "relative",
  },
  logo_op: {
    position: "absolute",
    top: "18%",
    left: "0%",
    width: 100,
    height: 50,
  },
  th: {
    backgroundColor: "#D7F3DD",
    fontWeight: "bold",
  },
  w10: {
    width: 50,
  },
  platform: {
    // borderTop: "1px solid black",
    borderBottom: "1px solid black",
    // padding: 5,
    fontSize: 12,
    textAlign: "center",
  },
  between: {
    justifyContent: "space-between",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
  },
  pic: {
    width: 100,
    height: 50,
  },
  w80: {
    width: 80,
  },
  company: {
    fontSize: 10,
    textAlign: "left",
  },
  f10: {
    fontSize: 10,
  },
});

// Watermark component to be used on each page
const Watermark = () => (
  <View fixed style={styles.watermark}>
    <Image src={watermark} style={styles.watermark} />
  </View>
);
const TableHeader = ({ tradeType }) => (
  <View style={styles.tableRow}>
    <View style={[styles.tableCol, styles.th, styles.w10]}>
      <Text style={[styles.tableCell]}>#</Text>
    </View>
    <View style={[styles.tableCol, styles.th]}>
      <Text style={styles.tableCell}>
        {/* {tradeType} */}
        {tradeType == "DEST" ? "Source" : "Destination"} Trader
      </Text>
    </View>
    <View style={[styles.tableCol, styles.th]}>
      <Text style={styles.tableCell}>Truck Number | Location </Text>
    </View>
    <View style={[styles.tableCol, styles.th]}>
      <Text style={styles.tableCell}>Dispatch Date</Text>
    </View>
    <View style={[styles.tableCol, styles.th, styles.w80]}>
      <Text style={styles.tableCell}>Arrival Date</Text>
    </View>
    <View style={[styles.tableCol, styles.th]}>
      <Text style={styles.tableCell}>Status | Mode</Text>
    </View>
  </View>
);

// Table Row Component
const TableRow = ({ item, tradeType, index }) => (
  <View style={styles.tableRow} key={index}>
    <View style={[styles.tableCol, styles.w10]}>
      <Text style={styles.tableCell}>{index}</Text>
    </View>
    <View style={styles.tableCol}>
      <Text style={styles.tableCell}>
        {tradeType == "DEST" ? item.srcTradeName : item.destTradeName}
      </Text>
      <Text style={styles.tableCell}>Trade ID: {item.traderSeq}</Text>
    </View>
    <View style={styles.tableCol}>
      <Text style={styles.tableCell}>
        {tradeType == "DEST" ? item.srcVehNum || "-" : item.destVehNum || "-"}
      </Text>
      <Text style={styles.tableCell}>
        {tradeType == "DEST"
          ? item.sourceAddressLine || ""
          : item.destAddressLine || ""}
      </Text>
    </View>
    <View style={styles.tableCol}>
      <Text style={styles.tableCell}>
        {" "}
        {item.date ? moment(item.date).format("DD-MMM-YYYY") : "" || "-"}
      </Text>
    </View>
    <View style={[styles.tableCol, styles.w80]}>
      <Text style={styles.tableCell}>
        {item.arlDate ? moment(item.arlDate).format("DD-MMM-YYYY") : "" || "-"}
      </Text>
    </View>
    <View style={styles.tableCol}>
      <Text style={[styles.tableCell, styles.bold]}>
        {tradeStatusText(item.tradeStatus)}
      </Text>
      <Text style={styles.tableCell}>
        {item.tradeMode == "ORDER" ? "Order Mode" : "Commission Mode"}
      </Text>
    </View>
  </View>
);
const ReportsDocument = ({ invoiceData, tradeType, fromDate, toDate }) => {
  const rowsPerPage = 12; // Adjust based on your layout and page size
  const chunks = [];
  for (let i = 0; i < invoiceData.length; i += rowsPerPage) {
    chunks.push(invoiceData.slice(i, i + rowsPerPage));
  }
  return (
    <Document>
      {chunks.map((chunk, pageIndex) => (
        <Page size="A4" style={styles.page}>
          <Watermark />
          {/* Header */}
          <View fixed style={styles.header}>
            <Image src={ono_logo} style={styles.logo} />
            <Text style={styles.header_head_main}>TRADE REPORTS</Text>
            <View style={styles.company}>
              <Text style={styles.bold}>ONO ARK INDIA PVT. LTD.</Text>
              <Text style={styles.f10}>No 148, Bhive Workspace,</Text>
              <Text style={styles.f10}>5th Main Road,HSR Layout,</Text>
              <Text style={styles.f10}>Bengaluru, Karnataka</Text>
            </View>
          </View>
          {/* Invoice Details */}
          <View style={styles.platform}>
            <Text>Platform Facilitator: ONO ARK INDIA PVT. LTD.</Text>
          </View>
          {/* Table */}
          <View style={styles.section}>
            <View style={[styles.flex, styles.between, styles.space]}>
              <View>
                <View style={styles.flex}>
                  {/* <Image src={defultProfilePic} style={styles.pic} /> */}
                  {tradeType == "DEST" ? (
                    <View>
                      <Text>Trade Name</Text>
                      <Text style={styles.bold}>
                        {invoiceData[0]?.destTradeName}{" "}
                      </Text>
                    </View>
                  ) : (
                    <View>
                      <Text>Trade Name</Text>
                      <Text style={styles.bold}>
                        {invoiceData[0]?.srcTradeName}{" "}
                      </Text>
                    </View>
                  )}
                </View>
              </View>

              {/* <View style={styles.col2}> */}
              <View>
                <Text>Trade Type: </Text>
                <Text style={styles.bold}>
                  {tradeType == "DEST" ? "Destination Trader" : "Source Trader"}
                </Text>
              </View>
              {/* </View> */}
              {/* <View style={styles.col3}> */}
              <View>
                <Text>Date Range </Text>
                <Text style={styles.bold}>
                  {fromDate ? moment(fromDate).format("DD-MMM-YYYY") : ""} -{" "}
                  {toDate ? moment(toDate).format("DD-MMM-YYYY") : ""}
                </Text>
              </View>
              {/* </View> */}
            </View>

            {/* Render the table header on every page */}
            <View style={styles.table}>
              <TableHeader tradeType={tradeType} />
              {/* Render table rows */}
              {chunk.map((invoiceData, i) => (
                <TableRow
                  key={i}
                  item={invoiceData}
                  tradeType={tradeType}
                  index={pageIndex * rowsPerPage + i + 1}
                />
              ))}
            </View>
          </View>
          {/* Footer with Page Numbers */}

          <View style={styles.footer} fixed>
            <Text style={styles?.footerPara}>
              NOTE: If your ledger doesn’t match the number of trades listed,
              please report the discrepancy right away to avoid any disputes.
              Contact us immediately at +91 83379 22222 or reply to this
              WhatsApp number.
            </Text>
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default ReportsDocument;
