import moment from "moment";

export function isArrivalDateStatus(arrivalDate, tradeDate) {
  if (arrivalDate != "") {
    var currentDate = moment(new Date(arrivalDate));
    var billDateVal = moment(new Date(tradeDate));
    var days_diff = currentDate.diff(billDateVal, "days");
    if (days_diff < 7) {
      return true;
    }
  }
  return false;
}

export default isArrivalDateStatus;
