import React, { useEffect, useState, memo } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import classes from "./style.module.scss";
import { getCurrentDateTime } from "../../../helpers/datetime";
const months_array = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const months_ful_array = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const getMonthsList = (year, maxYear = "", maxMonth = "") => {
  let monthsList = [];
  let m = 0;
  for (let month of months_ful_array) {
    let mon = m + 1;
    let lastDayD = new Date(year, mon, 0);
    let lastDate = `${year}-${
      mon <= 9 ? "0" + mon.toString() : mon
    }-${lastDayD.getDate()}`;
    let disable = false;
    if (maxYear && year > maxYear) {
      disable = true;
    } else if (maxYear && maxYear === year) {
      if (m > maxMonth) {
        disable = true;
      }
    }
    monthsList.push({
      month: mon,
      month_short_name: months_array[m],
      month_full_name: month,
      year: year,
      start_date: `${year}-${mon <= 9 ? "0" + mon.toString() : mon}-01`,
      end_date: lastDate,
      disable: disable,
    });
    m += 1;
  }
  return monthsList;
};
const todayDate = getCurrentDateTime();
const MonthlyCalander = ({ maxDate = "", date = "", onChange }) => {
  const [months, setMonths] = useState([]);
  const [activeMonth, setActiveMonth] = useState({ month: "", year: "" });
  const [navigation, setNavigation] = useState({ next: true, prev: true });
  const changeYear = (action) => {
    let y = action === "prev" ? activeMonth.year - 1 : activeMonth.year + 1;
    let monthlist = [];
    if (maxDate) {
      let maxd = new Date(maxDate);
      let maxYear = maxd.getFullYear();
      monthlist = getMonthsList(y, maxYear, maxd.getMonth());
      if (y >= maxYear) {
        setNavigation({ ...navigation, next: false });
      } else {
        setNavigation({ ...navigation, next: true });
      }
    } else {
      monthlist = getMonthsList(y, "", "");
    }
    setActiveMonth({ year: y, month: "" });
    setMonths(monthlist);
  };
  const handelClick = (data) => {
    if (data.disable === true) {
      return;
    }
    onChange && onChange(data.start_date, data.end_date);
    setActiveMonth(data);
  };
  useEffect(() => {
    if (date) {
      let d = new Date(date);
      let year = d.getFullYear();
      let m = d.getMonth();
      let month_name = months_ful_array[m];
      let max_year = "";
      let max_month = "";
      console.log("maxDate", maxDate);
      if (maxDate) {
        let maxd = new Date(maxDate);
        max_year = maxd.getFullYear();
        max_month = maxd.getMonth();
        if (year >= max_year) {
          setNavigation({ ...navigation, next: false });
        }
      }
      let monthlist = getMonthsList(year, max_year, max_month);
      setMonths(monthlist);
      setActiveMonth({ year: year, month: month_name });
    }
  }, [date]);
  return (
    <>
      <div>
        <div className={classes.header + " flex px-5 py-3"}>
          {navigation.prev && (
            <span
              className={`cursor-pointer`}
              onClick={() => {
                changeYear("prev");
              }}
            >
              <BsChevronLeft />
            </span>
          )}
          <span
            className="grow font-semibold text-center"
            style={{ fontSize: "20px", color: "var(--ono-blue)" }}
          >
            {activeMonth.year}
          </span>
          {navigation.next && (
            <span
              className="cursor-pointer"
              onClick={() => {
                changeYear("next");
              }}
            >
              <BsChevronRight />
            </span>
          )}
        </div>
        <div className="px-2 py-2 flex flex-wrap gap-y-4">
          {months.map((month) => (
            <span
              className={`py-2 text-center rounded-sm cursor-pointer text-sm ${
                classes.monthName
              } ${
                activeMonth.month === month.month_full_name &&
                activeMonth.year == month.year
                  ? classes.active
                  : "hv-primary"
              }`}
              data-disable={month.disable}
              style={{ flex: "0 0 25%" }}
              onClick={() => {
                handelClick(month);
              }}
            >
              {month.month_full_name}
            </span>
          ))}
        </div>
      </div>
    </>
  );
};
export default memo(MonthlyCalander);
