import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import InvoiceDocument from "./InvoiceDocument";
import moment from "moment";
import download from "../../../assets/images/download.svg";
import AcceptanceDocument from "./acceptanceDocument";
import { getCurrencyNumberWithSymbol } from "../../../helpers/get-currency-number";
const PDFAcceptance = (detailsData) => {
  console.log(
    detailsData?.srcName,
    detailsData?.detailsData,
    detailsData?.destAddrLine,
    "alltradeDetail"
  );
  const details = detailsData?.detailsData;
  const invoiceData = {
    // header: "Dynamic Invoice Header",
    // footer: "Dynamic Invoice Footer",
    // invoiceDate: "2024-08-23",
    // invoiceNumber: "12345",
    advanceAmt:
      details?.reqAdv != null
        ? getCurrencyNumberWithSymbol(details?.reqAdv?.requestAdv)
        : 0,
    tradeId: details?.tradeId,
    tradeSeq: details?.traderSeq,
    destName: details?.destName,
    destTradename: details?.destTradeName || "-",
    destAddrLine: details?.destAddrLine || "-",
    srcName: details?.srcName,
    dob: "-",
    date: moment(details?.date).format("DD-MMM-YY"),
    srcAddrLine: details?.srcAddrLine || "-",
    srcTradename: details?.srcTradeName || "-",
    srcMobile: details?.srcMobile,
    destMobile: details?.destMobile,
    timeStamp: moment(details?.acceptTradeDate).format("DD-MMM-YY | hh:mm:A"),
    srcEsign: details?.srcEsign,
    destEsign: details?.destEsign,
    // items: [
    //   {
    //     name: "Item 1",
    //     description: "Description of Item 1",
    //     quantity: 2,
    //     unitPrice: "$10.00",
    //     total: "$20.00",
    //   },
    // ],
  };

  return (
    <div className="py-2 flex justify-end">
      <PDFDownloadLink
        document={<AcceptanceDocument invoiceData={invoiceData} />}
        fileName="invoice.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            "Loading document..."
          ) : (
            <div className="flex flex-col justify-center items-center">
              <img src={download} className="w-8" />
              <p className="text-sm">Download</p>
            </div>
          )
        }
      </PDFDownloadLink>
    </div>
  );
};

export default PDFAcceptance;
