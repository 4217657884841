import { useContext, useState } from "react";
import { deviceInfoContext } from "../../context";
import {
  Input,
  Slider,
  RadioButton,
  Button,
  OTPInput,
} from "../../components/ui";
import { FaArrowRight } from "react-icons/fa6";
import { FaArrowLeft } from "react-icons/fa6";
import PageHeader from "../../components/commons/page-header";
import useLogin from "./useLogin";
import Illustration from "../../assets/images/Illustration.svg";
import loginbanner from "../../assets/images/Forgot password-rafiki.svg";
import logo from "../../assets/images/mandi-logo.svg";
import PageMainHeader from "../../components/commons/page-main-header";
import { GoArrowLeft } from "react-icons/go";
import toolTip from "../../assets/images/toolTip.svg";
import invoice from "../../assets/images/invoice.svg";
import arrow from "../../assets/images/arrow.svg";
const Login = () => {
  const { width } = useContext(deviceInfoContext);
  const {
    step,
    sentOtpBtnText,
    verifyOtp,
    setStep,
    onCreateAcount,
    setMobileNumber,
    getOtp,
    setOtpNumber,
    regStatus,
    setFullName,
    setTradeName,
    onChangeTrade,
    otpExpTime,
    tradeType,
  } = useLogin();

  return (
    <>
      {/* <PageMainHeader /> */}
      <PageHeader showTitle={true} title="Account Login" topVal="0" />
      {/* <div className="flex justify-center" style={{ height: "35vh" }}>
        <img src={loginbanner} className="h-full" />
      </div> */}
      <div className="py-5 px-3">
        <Slider step={step}>
          <div>
            <div className="px-2 flex gap-3">
              <div className="grow-[4]">
                <Input
                  label="Mobile Number"
                  type="number"
                  required={true}
                  onChange={(e) => {
                    setMobileNumber(e.target.value);
                  }}
                />
              </div>
              <div
                className="flex items-center grow-[2] justify-end ml-auto shrink-0"
                // style={{ width: "8rem" }}
              >
                <Button
                  className="mt-6"
                  onClick={getOtp}
                  disabled={otpExpTime > 0}
                  styles={{ width: "8rem" }}
                >
                  {sentOtpBtnText}
                </Button>
              </div>
            </div>
            <div className="px-2 mt-5 relative flex gap-3">
              {otpExpTime > 0 && (
                <span className="absolute right-3">
                  {otpExpTime.toString().padStart(2, "0")}:00
                </span>
              )}
              <div className="grow-[4]">
                <Input
                  label="Enter OTP"
                  type="number"
                  required={true}
                  onChange={(e) => {
                    setOtpNumber(e.target.value);
                  }}
                />
              </div>
              <div
                className="flex items-center grow-[2] justify-end ml-auto shrink-0"
                // style={{ width: "6rem" }}
              >
                <Button
                  className="mt-6"
                  onClick={verifyOtp}
                  styles={{ width: "8rem" }}
                  // disabled={otpExpTime > 0}
                >
                  NEXT
                </Button>
              </div>
              {/* <OTPInput
                label="Enter OTP"
                required={true}
                length={6}
                onChange={(otp) => {
                  setOtpNumber(otp);
                }}
              /> */}
            </div>
            {/* <div className="px-4 mt-4">
              <Button
                className="ml-auto w-32"
                shape="round"
                onClick={verifyOtp}
              >
                Next &nbsp;
                <FaArrowRight />
              </Button>
            </div> */}
          </div>
          {regStatus === "NEW_USER" ? (
            <div>
              <div className="px-2">
                <Input
                  label="Full Name"
                  palceholder=""
                  required={true}
                  onChange={(e) => {
                    setFullName(e.target.value);
                  }}
                />
              </div>
              <div className="px-2 mt-4">
                <label className="mr-1 text-sm font-semibold">
                  Trade Name<span className="color-red ml-1">{"*"}</span>
                </label>

                <Input
                  label=""
                  required={true}
                  onChange={(e) => {
                    setTradeName(e.target.value);
                  }}
                />
              </div>

              <div className="w-[97%] h-[150px] bg-gray-200 br-10 m-2">
                <div className="p-2 flex">
                  <img
                    src={toolTip}
                    alt="tooltip"
                    className="w-4 h-4 mt-[5px]"
                  />
                  <span className="text-sm ml-1 mt-[2px]">
                    Enter Trade Name as per your invoice header.
                  </span>
                </div>
                <div className="border-t border-gray-300 mt-1"></div>
                <span className="text-sm m-2">For Example:</span>
                <div className="flex">
                  <div>
                    <img
                      src={invoice}
                      alt="invoice"
                      className="m-2 w-[200px]"
                    />
                  </div>
                  <div>
                    <img src={arrow} alt="arrow" className="mt-4 w-8 h-8" />
                  </div>
                  <div className="mt-5 ml-1">
                    <span>
                      Trade Name is: <br />
                      <span className="font-semibold">ABC TRADING COMPANY</span>
                    </span>
                  </div>
                </div>
              </div>

              <div className="px-2 mt-4">
                <label className="text-sm font-semibold">
                  User Type <span className="color-red">{"*"}</span>
                </label>
                <RadioButton
                  displayType="horizontal"
                  data={[
                    { label: "Seller (Source Trader)", value: "SOURCE" },
                    { label: "Buyer (Destination Trader)", value: "DEST" },
                  ]}
                  value={tradeType}
                  pointer={{ label: "label", value: "value" }}
                  onChange={(value) => {
                    onChangeTrade(value);
                  }}
                />
              </div>
              <div className="px-4 mt-6 flex">
                <Button
                  className="colot-red p-0"
                  variant="transparent"
                  onClick={() => {
                    setStep(0);
                  }}
                >
                  <FaArrowLeft />
                  &nbsp; BACK
                </Button>
                <Button className="ml-auto w-32" onClick={onCreateAcount}>
                  SUBMIT &nbsp;
                  <FaArrowRight />
                </Button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </Slider>
        <div className="fixed left-0" style={{ width: width, bottom: "30vh" }}>
          <img src={logo} className="w-20 ml-auto mr-auto" />
        </div>
      </div>
      <div className="fixed bottom-0 left-0" style={{ width: width }}>
        <img src={Illustration} className="w-full" />
      </div>
    </>
  );
};
export default Login;
