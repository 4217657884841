import { FC, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { deviceInfoContext } from "../../../context";
import logo from "../../../assets/images/logo_yellow.svg";
import user from "../../../assets/images/user.svg";
import { useSelector } from "react-redux";
import { TRootState } from "../../../store";
import { FaHamburger } from "react-icons/fa";
import { useState } from "react";
import Popover from "../../ui/popover";
import DefultLogo from "../../../assets/images/single_bill.svg";
import classes from "./style.module.scss";
const PageMainHeader: FC<{}> = ({}) => {
  const { width } = useContext(deviceInfoContext);
  const { name, traderId, loggedin } = useSelector((state: TRootState) => ({
    name: state.auth.name,
    traderId: state.auth.traderId,
    loggedin: state.auth.loggedin,
  }));
  const navigate = useNavigate();
  const [showUserData, setShowUserData] = useState(false);

  return (
    <>
      <div
        className="bg-primary shadow-md flex justify-between items-center pr-2 font-semibold fixed top-0 z-10"
        style={{ height: "3.8rem", width: width, marginBottom: "60px" }}
      >
        {/* <div className="pr-3 pl-2 cursor-pointer h-full flex">
          <FaHamburger className="mt-auto mb-auto" />
        </div> */}

        <div
          className="pl-2 cursor-pointer"
          onClick={() => {
            navigate("/");
          }}
        >
          <img src={logo} className="w-16" />
        </div>
        {loggedin === true && (
          <span>
            <div
              className={"flex ml-auto gap-2 cursor-pointer"}
              onMouseOver={() => {
                setShowUserData(true);
              }}
            >
              <img src={user} className="ml-auto px-1" />
            </div>

            {showUserData && (
              <div>
                <Popover
                  open={showUserData}
                  onClose={() => {
                    setShowUserData(false);
                  }}
                  displayPosition="right"
                >
                  <div
                    className="flex gap-2 cursor-pointer p-3"
                    onClick={() => {
                      navigate("/my-profile");
                    }}
                  >
                    <img src={DefultLogo} className="w-8 h-8" />
                    <div className="flex flex-col text-xs font-medium">
                      <span>{name}</span>
                      <span className="pt-1 color-blue">{` User Id :  ${traderId}`}</span>
                    </div>
                  </div>
                </Popover>
              </div>
            )}
          </span>
        )}
      </div>
      <div style={{ height: "3.8rem" }}></div>
    </>
  );
};
export default PageMainHeader;
