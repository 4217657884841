import React, { useEffect, useState, FC } from "react";
import moment from "moment/moment";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { FaCalendarAlt } from "react-icons/fa";
import classes from "./style.module.scss";
const YearChanger: FC<{
  year: number;
  onClick?: () => void;
  onChange: (data: { fromDate: string; toDate: string; year: number }) => void;
  maxYear?: number;
}> = ({ year, onClick, onChange, maxYear }) => {
  const [animation, setAnimation] = useState("");
  const onBack = () => {
    //setAnimation("shake-left-to-right");
    let y = year - 1;
    onChange({ fromDate: `${y}-01-01`, toDate: `${y}-12-31`, year: y });
  };
  const onNext = () => {
    //setAnimation("shake-right-to-left");
    if (maxYear && year >= maxYear) {
      return;
    }
    let y = year + 1;
    onChange({ fromDate: `${y}-01-01`, toDate: `${y}-12-31`, year: y });
  };
  return (
    <div className="inline-flex items-center">
      <BsChevronLeft
        className={"br-50 cursor-pointer bg-white " + classes.icon}
        onClick={onBack}
      />
      <span
        className="flex items-center justify-center grow text-center mx-4 color-blue cursor-pointer w-28"
        onClick={() => {
          typeof onClick === "function" && onClick();
        }}
      >
        <FaCalendarAlt />
        <span className={"ml-1 " + animation} style={{ overflow: "hidden" }}>
          <span key={year}>{year}</span>
        </span>
      </span>
      <BsChevronRight
        className={`ml-auto br-50 bg-white ` + classes.icon}
        onClick={onNext}
      />
    </div>
  );
};
export default YearChanger;
