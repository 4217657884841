import { useState, lazy, FC, useEffect } from "react";
import moment from "moment";
import DateChanger from "../../commons/date-changer";
import Calender from "../../commons/calender";
import { getCurrentDateTime } from "../../../helpers/datetime";
import calenderIcon from "../../../assets/images/date_icon.svg";
import YearlyCalendar from "../../commons/calender/yearly-calendar";
import YearChanger from "../../commons/date-changer/year-changer";
import MonthChanger from "../../commons/date-changer/monthly";
import MonthlyCalander from "../../commons/monthly-calander";
import Popover from "../../ui/popover";
import { RadioButton } from "../../ui";
const todayDate = getCurrentDateTime(true);
const ONOCalendar: FC<{
  onDateChange?: (data: {
    fromDate: string;
    toDate: string;
    mode?: string;
  }) => void;
  mode?: string;
  hideModeSelection?: boolean;
  allDate?: boolean;
}> = ({ onDateChange, mode = "", hideModeSelection = false, allDate }) => {
  const [calander, setCalander] = useState({
    open: false,
    mode: "single",
    dateChangerMode: "single",
    fromDate: moment().format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
  });
  const onClickDate = () => {
    setCalander({ ...calander, open: !calander.open });
  };
  const onChangeDate = async (fromDate: string, toDate: string) => {
    setCalander({
      ...calander,
      fromDate: fromDate,
      toDate: toDate,
      dateChangerMode: calander.mode,
      open: false,
    });
    typeof onDateChange == "function" &&
      onDateChange({ fromDate: fromDate, toDate: toDate, mode: calander.mode });
  };
  const onChangeMonth = async (fromDate: string, toDate: string) => {
    setCalander({
      ...calander,
      fromDate: fromDate,
      toDate: toDate,
      dateChangerMode: "monthly",
      open: false,
    });
    typeof onDateChange == "function" &&
      onDateChange({ fromDate: fromDate, toDate: toDate, mode: "monthly" });
  };
  const onChangeYear = (data: any) => {
    setCalander({
      ...calander,
      open: false,
      dateChangerMode: "yearly",
      fromDate: data.fromDate,
      toDate: data.toDate,
    });
    typeof onDateChange == "function" &&
      onDateChange({
        fromDate: data.fromDate,
        toDate: data.toDate,
        mode: "yearly",
      });
  };
  useEffect(() => {
    if (mode !== "") {
      if (mode === "monthly") {
        setCalander({
          ...calander,
          mode: mode,
          dateChangerMode: mode,
        });
      } else if (mode === "weekly") {
        setCalander({
          ...calander,
          mode: mode,
          dateChangerMode: mode,
          fromDate: moment().startOf("week").format("YYYY-MM-DD"),
          toDate: moment().endOf("week").format("YYYY-MM-DD"),
        });
      } else {
        setCalander({ ...calander, mode: mode, dateChangerMode: mode });
      }
    }
  }, [mode]);
  const onChnageMode = (m: string) => {
    if (m === "weekly") {
      setCalander({
        ...calander,
        fromDate: moment(calander.fromDate)
          .startOf("week")
          .format("YYYY-MM-DD"),
        toDate: moment(calander.fromDate).endOf("week").format("YYYY-MM-DD"),
        mode: m,
      });
      return;
    }
    setCalander({ ...calander, mode: m });
  };
  return (
    <>
      <div className="flex justify-center">
        <div className="relative flex flex-col justify-center">
          {
            // calander.dateChangerMode === "all" ? (
            //   <span
            //     onClick={onClickDate}
            //     className="flex gap-2 color-blue cursor-pointer"
            //   >
            //     <img src={calenderIcon} />
            //     <span>All</span>
            //   </span>
            // ) :
            calander.dateChangerMode === "single" ? (
              <DateChanger
                date={
                  calander.fromDate === calander.toDate ? calander.fromDate : ""
                }
                onChange={(date: string) => {
                  onChangeDate(date, date);
                }}
                onClick={onClickDate}
                maxDate={todayDate}
              />
            ) : calander.dateChangerMode === "monthly" ? (
              <MonthChanger
                onClick={onClickDate}
                fromDate={calander.fromDate}
                toDate={calander.toDate}
                onChange={onChangeMonth}
                maxDate={todayDate}
              />
            ) : calander.dateChangerMode === "range" ||
              calander.dateChangerMode === "weekly" ? (
              <div
                className="color-blue flex gap-2 cursor-pointer"
                onClick={onClickDate}
              >
                <img src={calenderIcon} />
                <span>
                  {moment(calander.fromDate).format("DD-MMM-YYYY")} To{" "}
                  {moment(calander.toDate).format("DD-MMM-YYYY")}
                </span>
              </div>
            ) : calander.dateChangerMode === "yearly" ? (
              <YearChanger
                year={parseInt(moment(calander.fromDate).format("YYYY"))}
                maxYear={parseInt(moment().format("YYYY"))}
                onClick={() => {
                  setCalander({ ...calander, open: true });
                }}
                onChange={(data) => {
                  onChangeDate(data.fromDate, data.toDate);
                }}
              />
            ) : (
              <></>
            )
          }
        </div>
        <Popover
          style={{ width: "25rem" }}
          open={calander.open}
          onClose={() => {
            onClickDate();
          }}
        >
          <div className="py-4 px-4 border-bottom">
            {hideModeSelection === false && (
              <RadioButton
                value={calander.mode}
                data={[
                  { label: "Daily", value: "single" },
                  { label: "Weekly", value: "weekly" },
                  { label: "Monthly", value: "monthly" },
                  { label: "Yearly", value: "yearly" },
                  { label: "Custom", value: "range" },
                ]}
                pointer={{ label: "label", value: "value" }}
                onChange={(v) => {
                  onChnageMode(v.toLocaleString());
                }}
                styles={{ display: "flex" }}
              />
            )}
            {calander.mode === "single" ? (
              <Calender
                mode="single"
                onChange={(data: { fromdate: string; todate: string }) => {
                  onChangeDate(
                    moment(data.fromdate, "YYYY-MMM-DD").format("YYYY-MM-DD"),
                    moment(data.todate, "YYYY-MMM-DD").format("YYYY-MM-DD")
                  );
                }}
                currentDate={calander.fromDate}
                maxDate={moment().format("YYYY-MM-DD")}
                initialDateRange={{}}
                style={{}}
              />
            ) : calander.mode === "weekly" ? (
              <Calender
                mode="weekly"
                onChange={(data: { fromdate: string; todate: string }) => {
                  setCalander({ ...calander, open: false });
                  onChangeDate(
                    moment(data.fromdate, "YYYY-MMM-DD").format("YYYY-MM-DD"),
                    moment(data.todate, "YYYY-MMM-DD").format("YYYY-MM-DD")
                  );
                }}
                initialDateRange={{
                  fromdate: calander.fromDate,
                  todate: calander.toDate,
                }}
                maxDate={moment().format("YYYY-MM-DD")}
                style={{}}
              />
            ) : calander.mode === "monthly" ? (
              <MonthlyCalander
                maxDate={todayDate}
                onChange={onChangeMonth}
                date={calander.fromDate}
              />
            ) : calander.mode === "yearly" ? (
              <YearlyCalendar
                activeYear={parseInt(moment(calander.fromDate).format("YYYY"))}
                maxYear={parseInt(moment().format("YYYY"))}
                onSelectYear={onChangeYear}
              />
            ) : calander.mode === "range" ? (
              <Calender
                mode={"range"}
                onChange={(data: { fromdate: string; todate: string }) => {
                  onChangeDate(
                    moment(data.fromdate, "YYYY-MMM-DD").format("YYYY-MM-DD"),
                    moment(data.todate, "YYYY-MMM-DD").format("YYYY-MM-DD")
                  );
                }}
                initialDateRange={{
                  fromdate: calander.fromDate,
                  todate: calander.toDate,
                }}
                maxDate={moment().format("YYYY-MM-DD")}
                style={{}}
              />
            ) : (
              <></>
            )}
          </div>
        </Popover>
      </div>
    </>
  );
};
export default ONOCalendar;
