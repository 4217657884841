import moment from "moment";
export function isPaymentDateBeforeArrivalStatus(
  arrivalDate,
  tradeDate,
  paidDate
) {
  var fDate, lDate, cDate;
  fDate = Date.parse(tradeDate);
  lDate = Date.parse(arrivalDate);
  cDate = Date.parse(paidDate);
  console.log(fDate, lDate, cDate, "Dates");
  if (cDate <= lDate && cDate >= fDate) {
    console.log(fDate);
    return true;
  }
  //   if (arrivalDate != "") {
  //     var currentDate = moment(new Date(arrivalDate));
  //     var billDateVal = moment(new Date(tradeDate));
  //     var days_diff = currentDate.diff(billDateVal, "days");
  //     console.log(currentDate, billDateVal, days_diff, "dateif");
  //     if (days_diff < 7) {
  //       return true;
  //     }
  //   }
  //   return false;
}
export default isPaymentDateBeforeArrivalStatus;
