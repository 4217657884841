import React, { useEffect, useState, memo } from "react";
import moment from "moment/moment";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { FaCalendarAlt } from "react-icons/fa";
import classes from "./style.module.scss";
import { getCurrentDateTime } from "../../../helpers/datetime";

const DateChanger = ({ date = "", onChange, onClick, maxDate }) => {
  const [navigationFlag, setNavigationFlag] = useState({
    right: true,
    left: true,
  });
  const [currentDate, setDate] = useState({
    date: date ? moment(date) : moment(getCurrentDateTime(true)),
    action: "",
    animation: "",
  });
  const onNext = () => {
    setDate({
      ...currentDate,
      date: moment(currentDate.date).add(1, "day"),
      action: "forward",
      animation: "",
    });
  };
  const onBack = () => {
    setDate({
      ...currentDate,
      date: moment(currentDate.date).subtract(1, "day"),
      action: "backward",
      animation: "",
    });
  };
  useEffect(() => {
    if (maxDate) {
      let diff = moment(currentDate.date).diff(maxDate, "day", true);
      if (diff >= 0 && navigationFlag.right === true) {
        setNavigationFlag({ ...navigationFlag, right: false });
      }
      if (navigationFlag.right === false && diff < 0) {
        setNavigationFlag({ ...navigationFlag, right: true });
      }
    }
    if (typeof onChange === "function") {
      onChange(currentDate.date.format("YYYY-MM-DD"));
    }
    if (currentDate.action === "forward") {
      setDate({
        ...currentDate,
        action: "",
        animation: "slide-right-to-left",
      });
      return;
    }
    if (currentDate.action === "backward") {
      setDate({
        ...currentDate,
        action: "",
        animation: "slide-left-to-right",
      });
      return;
    }
  }, [currentDate.date]);
  useEffect(() => {
    date &&
      setDate({
        ...currentDate,
        date: moment(date),
      });
  }, [date]);
  let displayDate = moment(currentDate.date).format("DD-MMM-YYYY");
  return (
    <>
      <div className="inline-flex items-center">
        <BsChevronLeft
          className={"br-50 cursor-pointer bg-white " + classes.icon}
          onClick={onBack}
        />
        <span
          className="flex items-center grow text-center mx-4 color-blue cursor-pointer"
          onClick={() => {
            typeof onClick === "function" && onClick();
          }}
        >
          <FaCalendarAlt />
          <span className="ml-1 w-28" style={{ overflow: "hidden" }}>
            <span className={`${currentDate.animation}`} key={displayDate}>
              {displayDate}
            </span>
          </span>
        </span>
        <BsChevronRight
          className={
            `ml-auto br-50 ${
              navigationFlag.right ? "cursor-pointer" : "cursor-disabled"
            } bg-white ` + classes.icon
          }
          onClick={() => {
            navigationFlag.right && onNext();
          }}
        />
      </div>
    </>
  );
};
export default memo(DateChanger);
