import { FC, useEffect, useState } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
const noofYearsPerSlide = 10;
const YearlyCalendar: FC<{
  activeYear: number;
  maxYear?: number;
  onSelectYear: (data: {
    fromDate: string;
    toDate: string;
    year: number;
  }) => void;
}> = ({ activeYear, maxYear, onSelectYear }) => {
  const [years, setYears] = useState<number[]>([]);
  const [animation, setAnimation] = useState("");
  const changeYearRange = (direction: "forward" | "backward") => {
    if (direction === "forward") {
      setAnimation("shake-right-to-left");
      constructYears(years[noofYearsPerSlide - 1]);
    } else {
      setAnimation("shake-left-to-right");
      constructYears(years[0] - 3);
    }
  };
  const constructYears = (y: number) => {
    let startYear =
      parseInt(y.toString().substring(0, y.toString().length - 1) + "0") + 1;
    let year = startYear;
    let years = [];
    for (let i = 0; i <= noofYearsPerSlide - 1; i++) {
      years.push(year);
      year += 1;
    }
    setYears(years);
  };
  useEffect(() => {
    constructYears(activeYear);
  }, [activeYear]);
  return (
    <>
      <div className="flex justify-center items-center py-2 mt-2 px-5">
        <BsChevronLeft
          className="cursor-pointer h-5 w-5"
          onClick={() => {
            changeYearRange("backward");
          }}
        />
        <span className="grow text-center color-blue">
          {years[0]} - {years[years.length - 1]}
        </span>
        <BsChevronRight
          className="cursor-pointer h-5 w-5"
          onClick={() => {
            changeYearRange("forward");
          }}
        />
      </div>
      <div
        className={`px-2 py-2 flex flex-wrap gap-y-4 ${animation}`}
        key={`${years[0]} - ${years[years.length - 1]}`}
      >
        {years.map((year) => (
          <span
            className={`py-2 text-center rounded-sm cursor-pointer text-sm hv-primary`}
            data-disable={maxYear && year > maxYear}
            data-selected={year === activeYear}
            style={{ flex: "0 0 25%" }}
            onClick={() => {
              if (maxYear && year > maxYear) {
                return;
              }
              onSelectYear({
                fromDate: `${year}-01-01`,
                toDate: `${year}-12-31`,
                year: year,
              });
            }}
          >
            {year}
          </span>
        ))}
      </div>
    </>
  );
};
export default YearlyCalendar;
