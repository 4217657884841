import moment from "moment";
export const getCurrentDateTime = (onlyDate = false) => {
  if (onlyDate === true) {
    return moment(new Date()).format("YYYY-MM-DD");
  }
  return moment(new Date()).format("YYYY-MM-DD H:m:s");
};
export const formatDate = (dateTime, format = "YYYY-MM-DD H:m:s") => {
  return moment(dateTime).format(format);
};
