import React, { lazy, Suspense, useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import DeviceLayout from "./layout";
import AuthLayout from "./layout/auth-layout";
import NotFound from "./pages/not-found";
import "./App.css";
import MyProfile from "./pages/my-profile";
import PaymentHistory from "./pages/orders/order-view/payment-history";
import TermsAdvance from "./pages/orders/order-view/terms-advance";
import TermsAcceptance from "./pages/orders/order-view/terms-acceptance";
// import PageLayout from "./layout/page-layout";
import Reports from "./pages/Reports";
import { deviceInfoContext } from "./context";
const Orders = lazy(() => import("./pages/orders"));
const OrderView = lazy(() => import("./pages/orders/order-view"));
const CreateOrder = lazy(() => import("./pages/orders/create-order"));
const KycDocs = lazy(() => import("./pages/login/kycDocs"));

function App() { 

  return (
    <DeviceLayout>
      <AuthLayout>
        {/* <PageLayout> */}
        <Suspense fallback={<>Loading...</>}>
          <Routes>
            <Route path="/" element={<Orders />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/order-view/:tradeid" element={<OrderView />} />
            <Route path="/create-order" element={<CreateOrder />} />
           <Route path="/kyc-verification" element={<KycDocs />} />
           <Route path="/kyc-verification/edit" element={<KycDocs mode="EDIT"/>} />
            <Route
              path="/create-order/:tradeid/edit"
              element={<CreateOrder mode="edit" />}
            />
            <Route path="/my-profile" element={<MyProfile />} />
            <Route path="/:param" element={<NotFound />} />
            <Route
              path="/payment-history/:paymentId"
              element={<PaymentHistory />}
            />
            <Route path="/terms-advance/:tradeid" element={<TermsAdvance />} />
            <Route
              path="/terms-acceptance/:tradeid"
              element={<TermsAcceptance fromPdfView={true} />}
            />
            <Route path="/reports" element={<Reports />} />
          </Routes>
        </Suspense>
        {/* </PageLayout> */}
      </AuthLayout>
    </DeviceLayout>
  );
}

export default App;
